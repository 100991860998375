/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GetSingleWorkAction } from '../../../redux/actions/WorkAction'
import WorkDetails from '../../../components/workcomponent/WorkDetails'
import { Box, Stat, StatLabel, useColorModeValue, Flex, useToast } from '@chakra-ui/react'
import Loader from '../../../shared/loader/Loader'
import EmployeeList from '../../../components/workcomponent/EmployeeList'

export default function SelectSealPerson() {

    const location = useLocation()
    const dispatch = useDispatch()

    const textColor = useColorModeValue("secondaryGray.900", "white");

    const toast = useToast()

    const { loading, singleworks, singleworkerror } = useSelector((state) => state.singlework)

    useMemo(() => dispatch(GetSingleWorkAction(location.state.workid)), [dispatch, location])

    useEffect(() => {
        if (singleworkerror) {
            toast({ title: singleworkerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
    }, [singleworkerror])


    return (
        <>
            {loading && <Loader visible={loading} />}
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={{ base: 5 }}>
                <Flex my='auto' h='100%' align={{ base: "center", xl: "flex-start" }} justify={{ base: "center", xl: "center" }} direction={{ base: "column" }} gap={5}>
                    <Stat my='auto' ms={"0px"} gap={{ base: 5 }}>
                        <StatLabel color={textColor} fontSize={{ base: "2xl", }} textTransform={"uppercase"} fontWeight={600}>Work Details</StatLabel>
                    </Stat>
                    <WorkDetails items={singleworks?.works} />
                </Flex>

                <Flex my='auto' mt={{ base: 5 }} h='100%' align={{ base: "center", xl: "flex-start" }} justify={{ base: "center", xl: "center" }} direction={{ base: "column" }} gap={5}>
                    <Stat my='auto' ms={"0px"} gap={{ base: 5 }}>
                        <StatLabel color={textColor} fontSize={{ base: "2xl", }} textTransform={"uppercase"} fontWeight={600}>Employee List</StatLabel>
                    </Stat>
                    <EmployeeList workid={location.state.workid} />
                </Flex>
            </Box>
        </>
    )
}
