import axios from "../../config/axios"
import { CLONE_QUOTATION_FAILED, CLONE_QUOTATION_REQUEST, CLONE_QUOTATION_SUCCESS, DELETE_QUOTATION_FAILED, DELETE_QUOTATION_REQUEST, DELETE_QUOTATION_SUCCESS, GET_ALL_QUOTATION_FAILED, GET_ALL_QUOTATION_REQUEST, GET_ALL_QUOTATION_SUCCESS, HIRE_QUOTATION_FAILED, HIRE_QUOTATION_REQUEST, HIRE_QUOTATION_SUCCESS } from "../constants/QuotationConstants"

export const GetAllQuotationAction = (data) => (dispatch) => {
    try {
        dispatch({ type: GET_ALL_QUOTATION_REQUEST })
        axios.get(`/api/quotation/get-all-quotation`, { params: data }).then(({ data }) => {
            if (data.success) {
                dispatch({ type: GET_ALL_QUOTATION_SUCCESS, payload: data })
            } else {
                dispatch({ type: GET_ALL_QUOTATION_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: GET_ALL_QUOTATION_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: GET_ALL_QUOTATION_FAILED, payload: error.toString() })
    }
}

export const DeleteQuotationAction = (data) => (dispatch) => {
    try {
        dispatch({ type: DELETE_QUOTATION_REQUEST })
        axios.delete(`/api/quotation/delete-quotation/${data}`).then(({ data }) => {
            if (data.success) {
                dispatch({ type: DELETE_QUOTATION_SUCCESS, payload: data })
            } else {
                dispatch({ type: DELETE_QUOTATION_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: DELETE_QUOTATION_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: DELETE_QUOTATION_FAILED, payload: error.toString() })
    }
}

export const HireQuotationAction = (data) => (dispatch) => {
    try {
        dispatch({ type: HIRE_QUOTATION_REQUEST })
        axios.post(`/api/quotation/hire-quotation`, data).then(({ data }) => {
            if (data.success) {
                dispatch({ type: HIRE_QUOTATION_SUCCESS, payload: data })
            } else {
                dispatch({ type: HIRE_QUOTATION_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: HIRE_QUOTATION_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: HIRE_QUOTATION_FAILED, payload: error.toString() })
    }
}

export const CloneQuotationAction = (data) => (dispatch) => {
    try {
        dispatch({ type: CLONE_QUOTATION_REQUEST })
        axios.get(`/api/quotation/clone-quotation/${data}`).then(({ data }) => {
            if (data.success) {
                dispatch({ type: CLONE_QUOTATION_SUCCESS, payload: data })
            } else {
                dispatch({ type: CLONE_QUOTATION_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: CLONE_QUOTATION_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: CLONE_QUOTATION_FAILED, payload: error.toString() })
    }
}