export const GET_ALL_QUOTATION_REQUEST = "GET_ALL_QUOTATION_REQUEST"
export const GET_ALL_QUOTATION_SUCCESS = "GET_ALL_QUOTATION_SUCCESS"
export const GET_ALL_QUOTATION_FAILED = "GET_ALL_QUOTATION_FAILED"

export const DELETE_QUOTATION_REQUEST = "DELETE_QUOTATION_REQUEST"
export const DELETE_QUOTATION_SUCCESS = "DELETE_QUOTATION_SUCCESS"
export const DELETE_QUOTATION_FAILED = "DELETE_QUOTATION_FAILED"

export const HIRE_QUOTATION_REQUEST = "HIRE_QUOTATION_REQUEST"
export const HIRE_QUOTATION_SUCCESS = "HIRE_QUOTATION_SUCCESS"
export const HIRE_QUOTATION_FAILED = "HIRE_QUOTATION_FAILED"

export const CLONE_QUOTATION_REQUEST = "CLONE_QUOTATION_REQUEST"
export const CLONE_QUOTATION_SUCCESS = "CLONE_QUOTATION_SUCCESS"
export const CLONE_QUOTATION_FAILED = "CLONE_QUOTATION_FAILED"