import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Flex, FormControl, FormLabel, Heading, Icon, Input, InputGroup, InputRightElement, Text, useColorModeValue, useToast } from "@chakra-ui/react";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useForm } from "react-hook-form";

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch, useSelector } from 'react-redux'
import { loginaction } from "../../../redux/actions/AuthAction";
import axios from "../../../config/axios";
import Loader from "../../../shared/loader/Loader";

export default function SignIn() {

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [show, setShow] = React.useState(false);
  const [email, setemail] = useState("")

  const [Loading, setLoading] = useState(false)

  const handleClick = () => setShow(!show);

  const FormState = Yup.object().shape({
    email: Yup.string().required("Provide your email id"),
    password: Yup.string().required("Provide your password").min(6, "Password length must gratter than 6")
  })

  const { register, handleSubmit, formState: { errors }, } = useForm({ mode: 'all', resolver: yupResolver(FormState) })

  const { loading } = useSelector((state) => state.authdetails)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()

  const FormSubmit = (data) => {
    dispatch(loginaction(data))
  }

  const forgetpassword = () => {
    if (email === "") {
      return toast({ title: 'Enter your email first', status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
    }
    setLoading(true);
    axios.post('/api/auth/forgetpassword', { email }).then(({ data }) => {
      setLoading(false);
      if (data.success) {
        toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        navigate('/verify-otp', { state: { email } })
      } else {
        toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
      }
    }).catch((error) => {
      setLoading(false);
      toast({ title: error.response ? error.response.data.message : error.toString(), status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
    });
  }

  return (
    <>
      {Loading && <Loader visible={Loading} />}
      <Flex maxW={{ base: "100%", md: "max-content" }} w='100%' mx={{ base: "auto", lg: "0px" }} me='auto' h='100%' alignItems='start' justifyContent='center' mb={{ base: "30px", md: "60px" }} px={{ base: "25px", md: "0px" }} mt={{ base: "40px", md: "14vh" }} flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text mb='36px' ms='4px' color={textColorSecondary} fontWeight='400' fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>

        <Flex zIndex='2' direction='column' w={{ base: "100%", md: "420px" }} maxW='100%' background='transparent' borderRadius='15px' mx={{ base: "auto", lg: "unset" }} me='auto' mb={{ base: "20px", md: "auto" }}>

          <form onSubmit={handleSubmit(FormSubmit)}>
            <FormControl mb={'24px'}>
              <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input isRequired={true} variant='auth' fontSize='sm' ms={{ base: "0px", md: "0px" }} type='email' placeholder='mail@simmmple.com' mb='4px' fontWeight='500' size='lg' {...register("email", { onChange: (e) => setemail(e.target.value) })} />
              {errors.email && <Text color={"red"}>{errors.email.message}</Text>}
            </FormControl>

            <FormControl mb={'24px'}>
              <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input isRequired={true} fontSize='sm' placeholder='Min. 6 characters' mb='4px' size='lg' type={show ? "text" : "password"} variant='auth' {...register("password")} />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon color={textColorSecondary} _hover={{ cursor: "pointer" }} as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={handleClick} />
                </InputRightElement>
              </InputGroup>
              {errors.password && <Text color={"red"}>{errors.password.message}</Text>}
            </FormControl>

            <Flex justifyContent='flex-end' align='center' mb='24px'>

              <Button as={'a'} p={0} border={"none"} background={"transparent"} _hover={{ background: "transparent" }} cursor={'pointer'} onClick={() => forgetpassword()}>
                <Text color={textColorBrand} fontSize='sm' w='124px' fontWeight='500'> Forgot password{" "}? </Text>
              </Button>
            </Flex>

            <Button fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='24px' type="submit" isLoading={loading}>
              Sign In
            </Button>

          </form>

        </Flex>
      </Flex>
    </>
  );
}
