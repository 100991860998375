import { DELETE_WORK_FAILED, DELETE_WORK_REQUEST, DELETE_WORK_SUCCESS, GET_ALL_WORK_FAILED, GET_ALL_WORK_REQUEST, GET_ALL_WORK_SUCCESS, GET_SINGLE_WORK_FAILED, GET_SINGLE_WORK_REQUEST, GET_SINGLE_WORK_SUCCESS, TOOGLE_WORK_FAILED, TOOGLE_WORK_REQUEST, TOOGLE_WORK_SUCCESS } from "../constants/WorkConstance";

export const GetAllWorkReducer = (state = { works: [] }, action) => {
    switch (action.type) {
        case GET_ALL_WORK_REQUEST:
            return { ...state, loading: true };

        case GET_ALL_WORK_SUCCESS:
            return { loading: false, works: action.payload }

        case GET_ALL_WORK_FAILED:
            return { loading: false, workerror: action.payload }

        default:
            return state;
    }
}

export const GetSingleWorkReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_SINGLE_WORK_REQUEST:
            return { loading: true };

        case GET_SINGLE_WORK_SUCCESS:
            return { loading: false, singleworks: action.payload }

        case GET_SINGLE_WORK_FAILED:
            return { loading: false, singleworkerror: action.payload }

        default:
            return state;
    }
}

export const ToogleWorkReducer = (state = {}, action) => {
    switch (action.type) {
        case TOOGLE_WORK_REQUEST:
            return { toogleloading: true };

        case TOOGLE_WORK_SUCCESS:
            return { toogleloading: false, work: action.payload }

        case TOOGLE_WORK_FAILED:
            return { toogleloading: false, toogleerror: action.payload }

        default:
            return state;
    }
}

export const DeleteWorkReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_WORK_REQUEST:
            return { deleteloading: true };

        case DELETE_WORK_SUCCESS:
            return { deleteloading: false, deletework: action.payload }

        case DELETE_WORK_FAILED:
            return { deleteloading: false, deleteerror: action.payload }

        default:
            return state;
    }
}