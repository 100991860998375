import { createStore, combineReducers, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk';
// import { composeWithDevTools } from '@redux-devtools/extension';
import { loginreducer } from './redux/reducers/AuthReducers';
import { DeleteProductReducer, GetAllProductReducer, ToogleProductReducer } from './redux/reducers/ProductReducer';
import { LOGOUT_USER_FAILED, LOGOUT_USER_SUCCESS } from './redux/constants/AuthConstants';
import { DeleteEmployeeReducer, GetAllEmployeeReducer, ToogleEmployeeReducer } from './redux/reducers/EmployeeReducer';
import { DeleteWorkReducer, GetAllWorkReducer, GetSingleWorkReducer, ToogleWorkReducer } from './redux/reducers/WorkReducer';
import { CloneQuotationReducer, DeleteQuotationReducer, GetAllQuotationReducer, HireQuotationReducer } from './redux/reducers/QuotationReducers';
import { DeleteRoomReducer, GetAllRoomReducer } from './redux/reducers/RoomReducers';

const token = localStorage.getItem('token') ? localStorage.getItem('token') : null

const reducer = combineReducers({
    authdetails: loginreducer,
    getproduct: GetAllProductReducer,
    toogleproduct: ToogleProductReducer,
    deleteproduct: DeleteProductReducer,
    getemployee: GetAllEmployeeReducer,
    toogleemployee: ToogleEmployeeReducer,
    deleteemployee: DeleteEmployeeReducer,
    getwork: GetAllWorkReducer,
    singlework: GetSingleWorkReducer,
    tooglework: ToogleWorkReducer,
    deletework: DeleteWorkReducer,
    getquotation: GetAllQuotationReducer,
    deletequotation: DeleteQuotationReducer,
    hirequotation: HireQuotationReducer,
    clonequotation: CloneQuotationReducer,
    getroom: GetAllRoomReducer,
    deleteroom: DeleteRoomReducer
})

const rootReducer = (state, action) => {
    if ((action.type === LOGOUT_USER_SUCCESS) || (action.type === LOGOUT_USER_FAILED)) {
        return reducer(undefined, action)
    }

    return reducer(state, action)
}

const initialState = {
    authdetails: { userdetails: { token } }
}

const store = createStore(rootReducer, initialState, applyMiddleware(thunk));

export default store