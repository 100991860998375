/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, ModalOverlay, ModalContent, useColorModeValue, FormHelperText, useToast, Select } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MdArrowDropDown } from 'react-icons/md';
import axios from '../../config/axios';
import { GetAllWorkAction } from '../../redux/actions/WorkAction';
import PhoneInput from 'react-phone-input-2'
import moment from 'moment';

export default function EditWork({ work, limit, page, setworkdetails, day, search }) {

  const [isLoading, setisLoading] = useState(false)

  const textColor = useColorModeValue("secondaryGray", "white");
  const toast = useToast()
  const dispatch = useDispatch()

  const FormSchima = Yup.object().shape({
    customerName: Yup.string().required('Provide customer name'),
    customerAddress: Yup.string().required('Provide customer address'),
    projectName: Yup.string().required('Provide your work name'),
    location: Yup.string().required('Provide your work location'),
    consultationStatus: Yup.string().required('Provide your consultancy status'),
    consultationDate: Yup.string().required('Provide your consultancy date'),
    mobile: Yup.string().required('Provide mobile number'),
  })

  const { register, handleSubmit, formState: { errors }, reset, control, setValue } = useForm({ resolver: yupResolver(FormSchima), mode: 'all' })

  useEffect(() => {
    setValue('customerName', work?.customerName);
    setValue('customerAddress', work?.customerAddress);
    setValue('projectName', work?.projectName);
    setValue('location', work?.location);
    setValue('consultationStatus', work?.consultationStatus);
    setValue('consultationDate', moment(work?.consultationDate).format('YYYY-MM-DD'));
    setValue("mobile", "+" + work.customercountrycode + work.customermobile)
  }, [work]);

  const onSubmit = (data) => {
    data = { ...data, mobile: data.mobile.replace(`91`, ''), countrycode: 91 }
    setisLoading(true)
    axios.put(`/api/work/update-work/${work._id}`, data).then(({ data }) => {
      setisLoading(false)
      if (data.success) {
        toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        dispatch(GetAllWorkAction({ limit, page, day, search }))
        reset()
        setworkdetails(null)
      } else {
        toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
      }
    }).catch((error) => {
      setisLoading(false)
      toast({ title: error.response.data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
    })
  }

  return (
    <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={() => setworkdetails(null)} isOpen={true} isCentered motionPreset='slideInBottom'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Work</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb={6}>
            <FormControl mb={10}>
              <FormLabel>Customer Name</FormLabel>
              <Input type='text' color={textColor} {...register("customerName")} />
              {errors.customerName && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.customerName.message}</FormHelperText>}
            </FormControl>
            <FormControl mb={10}>
              <FormLabel>Customer Address</FormLabel>
              <Input type='text' color={textColor} {...register("customerAddress")} />
              {errors.customerAddress && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.customerAddress.message}</FormHelperText>}
            </FormControl>
            <FormControl mb={10}>
              <FormLabel>Customer Mobile Number</FormLabel>
              <Controller name="mobile" control={control} render={({ field: { onChange, value } }) => (
                <PhoneInput country={'in'} value={value} onChange={phone => onChange(phone)} containerClass='chakra-input css-1ixluli' containerStyle={{ borderColor: 'inherit' }} inputStyle={{ width: "100%", height: "100%", background: "transparent", border: "none", color: textColor }} buttonStyle={{ backgroundColor: 'transparent', border: 'none' }} />
              )} />
              {errors.mobile && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.mobile.message}</FormHelperText>}
            </FormControl>

            <FormControl mb={10}>
              <FormLabel>Project Name</FormLabel>
              <Input type='text' color={textColor} {...register("projectName")} />
              {errors.projectName && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.projectName.message}</FormHelperText>}
            </FormControl>
            <FormControl mb={10}>
              <FormLabel>Project Location</FormLabel>
              <Input type='text' color={textColor} {...register("location")} />
              {errors.location && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.location.message}</FormHelperText>}
            </FormControl>
            <FormControl mb={10}>
              <FormLabel>Project Consultantion Status</FormLabel>
              <Select variant='outline' color={textColor} icon={<MdArrowDropDown />} {...register("consultationStatus")}>
                <option value="" color={textColor}>Select Consultancy Status</option>
                {
                  ["pending", "approved", "rejected"].map((item) => (
                    <option key={item} value={item} color={textColor}>{item.toLocaleUpperCase()}</option>
                  ))
                }
              </Select>
              {errors.consultationStatus && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.consultationStatus.message}</FormHelperText>}
            </FormControl>
            <FormControl mb={10}>
              <FormLabel>Project Consultantion Date</FormLabel>
              <Input type='date' color={textColor} {...register("consultationDate")} min={new Date()} />
              {errors.consultationDate && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.consultationDate.message}</FormHelperText>}
            </FormControl>
          </ModalBody>

          <ModalFooter gap={5}>
            <Button type='reset' colorScheme='cyan' onClick={() => reset()}> Reset </Button>
            <Button colorScheme='teal' type='submit' isLoading={isLoading}> Submit </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
