import React from 'react'
import Card from '../../shared/card/Card'
import { SimpleGrid, Image, Text } from '@chakra-ui/react'

export default function PhotosComponent({ photos, showPhoto }) {
    return (
        <Card py='5px'>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "xl": 2, "2xl": 2, "3xl": 6 }} gap='5px' mt={{ base: 6 }} mb='20px'>
                {
                    photos && (photos.length > 0) ? photos.map((items) => (
                        <Image key={items._id} src={items.url} width={200} height={200} objectFit={"contain"} cursor={"pointer"} onClick={() => showPhoto(items.url)} />
                    )) : (
                        <Text w={"80dvw"} paddingX={20} textAlign={"center"}> No Photos Found Add Some Photos For Display </Text>
                    )
                }
            </SimpleGrid>
        </Card>
    )
}
