export const GET_ALL_WORK_REQUEST = "GET_ALL_WORK_REQUEST"

export const GET_ALL_WORK_SUCCESS = "GET_ALL_WORK_SUCCESS"

export const GET_ALL_WORK_FAILED = "GET_ALL_WORK_FAILED"

export const GET_SINGLE_WORK_REQUEST = "GET_SINGLE_WORK_REQUEST"

export const GET_SINGLE_WORK_SUCCESS = "GET_SINGLE_WORK_SUCCESS"

export const GET_SINGLE_WORK_FAILED = "GET_SINGLE_WORK_FAILED"

export const TOOGLE_WORK_REQUEST = "TOOGLE_WORK_REQUEST"

export const TOOGLE_WORK_SUCCESS = "TOOGLE_WORK_SUCCESS"

export const TOOGLE_WORK_FAILED = "TOOGLE_WORK_FAILED"

export const DELETE_WORK_REQUEST = "DELETE_WORK_REQUEST"

export const DELETE_WORK_SUCCESS = "DELETE_WORK_SUCCESS"

export const DELETE_WORK_FAILED = "DELETE_WORK_FAILED"