import { DELETE_PRODUCT_FAILED, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, GET_ALL_PRODUCT_FAILED, GET_ALL_PRODUCT_REQUEST, GET_ALL_PRODUCT_SUCCESS, TOOGLE_PRODUCT_FAILED, TOOGLE_PRODUCT_REQUEST, TOOGLE_PRODUCT_SUCCESS } from "../constants/ProductConstant";

export const GetAllProductReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCT_REQUEST:
            return { ...state, loading: true };

        case GET_ALL_PRODUCT_SUCCESS:
            return { loading: false, products: action.payload }

        case GET_ALL_PRODUCT_FAILED:
            return { loading: false, producterror: action.payload }

        default:
            return state;
    }
}

export const ToogleProductReducer = (state = {}, action) => {
    switch (action.type) {
        case TOOGLE_PRODUCT_REQUEST:
            return { toogleloading: true };

        case TOOGLE_PRODUCT_SUCCESS:
            return { toogleloading: false, product: action.payload }

        case TOOGLE_PRODUCT_FAILED:
            return { toogleloading: false, toogleerror: action.payload }

        default:
            return state;
    }
}

export const DeleteProductReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_PRODUCT_REQUEST:
            return { deleteloading: true };

        case DELETE_PRODUCT_SUCCESS:
            return { deleteloading: false, deleteproduct: action.payload }

        case DELETE_PRODUCT_FAILED:
            return { deleteloading: false, deleteerror: action.payload }

        default:
            return state;
    }
}