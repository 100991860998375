import { LOGIN_USER_FAILED, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGOUT_USER_FAILED, LOGOUT_USER_REQUEST, LOGOUT_USER_SUCCESS } from "../constants/AuthConstants";

export const loginreducer = (state = { userdetails: {} }, action) => {
    switch (action.type) {
        case LOGIN_USER_REQUEST:
            return { loading: true }

        case LOGIN_USER_SUCCESS:
            return { loading: false, userdetails: action.payload }

        case LOGIN_USER_FAILED:
            return { loading: false, userdetailserror: action.payload }

        case LOGOUT_USER_REQUEST:
            return { ...state, loading: true }

        case LOGOUT_USER_SUCCESS:
            return { loading: false, logout: action.payload };

        case LOGOUT_USER_FAILED:
            return { loading: false, logouterror: action.payload };

        default:
            return state;
    }
}