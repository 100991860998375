import React from 'react'
import { Flex } from '@chakra-ui/react';
import { Hourglass } from 'react-loader-spinner'

export default function Loader({ visible }) {
    return (
        <Flex position={"fixed"} zIndex={9999999} alignItems={"center"} w={"100dvw"} h={"100dvh"} backgroundColor={"#0000008c"} opacity={1} justifyContent={"center"} pointerEvents={"auto"}>
            <Hourglass visible={visible} height="80" width="80" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#2596be', '#2596be']} />
        </Flex>
    )
}
