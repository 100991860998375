import axios from "../../config/axios"
import { LOGIN_USER_FAILED, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGOUT_USER_FAILED, LOGOUT_USER_REQUEST, LOGOUT_USER_SUCCESS } from "../constants/AuthConstants"

export const loginaction = (data) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_USER_REQUEST })
        await axios.post('/api/auth/login', data).then(({ data }) => {
            if (data.success) {
                dispatch({ type: LOGIN_USER_SUCCESS, payload: data })
                localStorage.setItem('token', data.token)
            } else {
                console.log(data)
                dispatch({ type: LOGIN_USER_FAILED, payload: data.message })
            }
        }).catch((error) => {
            console.log(error)
            dispatch({ type: LOGIN_USER_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: LOGIN_USER_FAILED, payload: error.toString() })
    }
}

export const logoutaction = () => async (dispatch) => {
    try {
        dispatch({ type: LOGOUT_USER_REQUEST })
        await axios.get('/api/auth/logout').then(({ data }) => {
            localStorage.clear()
            sessionStorage.clear()
            if (data.success) {
                dispatch({ type: LOGOUT_USER_SUCCESS, payload: data })
            } else {
                dispatch({ type: LOGOUT_USER_FAILED, payload: data.message })
            }
        }).catch((error) => {
            localStorage.clear()
            console.log(error)
            dispatch({ type: LOGOUT_USER_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: LOGIN_USER_FAILED, payload: error.toString() })
    }
}