import React from 'react'
import { Flex, Stat, StatLabel, StatNumber, useColorModeValue, Menu, MenuButton, Button, MenuList, MenuItem, Icon, Image } from '@chakra-ui/react'
import Card from '../../shared/card/Card';
import { DragHandleIcon } from '@chakra-ui/icons'
import { MdEdit, MdOutlineToggleOff, MdOutlineToggleOn } from 'react-icons/md';
import { useDispatch } from 'react-redux'
import { ToogleProductAction } from '../../redux/actions/ProductAction';

export default function ProductCard({ name, value, isActive, id, setproductdetails, imageUrl }) {

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "secondaryGray.800";

    const dispatch = useDispatch()

    return (
        <Card py='15px' border={"1px solid"} borderColor={isActive ? "#27ff00" : "#ff2f00"}>
            {
                imageUrl && (
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "center" }} justify={{ base: "center", xl: "center" }}>
                        <Image key={id} src={imageUrl} width={200} height={200} objectFit={"contain"} cursor={"pointer"} />
                    </Flex>
                )
            }
            <Flex my='auto' h='100%' align={{ base: "center", xl: "center" }} justify={{ base: "center", xl: "center" }}>
                <Stat my='auto' ms={"0px"}>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }}>
                        {name}
                    </StatLabel>
                    <StatNumber color={textColor} fontSize={{ base: "2xl", }}>
                        {value}
                    </StatNumber>
                </Stat>
                <Flex ms='auto' w='max-content'>
                    <Menu>
                        <MenuButton as={Button}>
                            <DragHandleIcon />
                        </MenuButton>
                        <MenuList>
                            <MenuItem gap={2} onClick={() => setproductdetails({ name, value, isActive, id })}><Icon as={MdEdit} width='20px' height='20px' color='inherit' /> Edit Product</MenuItem>
                            <MenuItem gap={2} onClick={() => dispatch(ToogleProductAction(id))}><Icon as={isActive ? MdOutlineToggleOff : MdOutlineToggleOn} width='20px' height='20px' color='inherit' /> Toogle Product</MenuItem>
                            {/* <MenuItem gap={2} onClick={() => dispatch(DeleteProductAction(id))}><Icon as={MdDeleteOutline} width='20px' height='20px' color='inherit' /> Delete Product</MenuItem> */}
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>
        </Card>
    )
}
