import React, { useState } from 'react'
import { Button, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, ModalContent, useColorModeValue, useToast, Select } from '@chakra-ui/react'
import axios from '../../config/axios';
import { GetAllRoomsReducer } from '../../redux/actions/RoomAction';
import { useDispatch } from 'react-redux'
import { MdArrowDropDown } from 'react-icons/md';

export default function DiscoundSection({ children, workid, quotationid }) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const textColor = useColorModeValue("secondaryGray", "white");
    const dispatch = useDispatch()
    const toast = useToast()

    const [isLoading, setisLoading] = useState(false)
    const [discount, setdiscount] = useState("")

    const CompleteGenrate = async () => {
        setisLoading(true);
        await axios.get(`/api/quotation/generate-pdf`, { params: { workid, quotationid, discount } }).then(({ data }) => {
            setisLoading(false);
            if (data.success) {
                dispatch(GetAllRoomsReducer(quotationid))
                toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            setisLoading(false);
            toast({ title: error.response ? error.reaponse.data.message : error.toString(), status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        });
    };


    return (
        <>
            {
                children && (<span onClick={onOpen}>{children}</span>)
            }

            <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered motionPreset='slideInBottom' size={"xl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Generate PDF</ModalHeader>
                    <ModalCloseButton />
                    <form>
                        <ModalBody pb={6}>
                            <FormControl mb={10}>
                                <FormLabel>Select Discount Parcentage</FormLabel>
                                <Select variant='outline' color={textColor} icon={<MdArrowDropDown />} onChange={(e) => setdiscount(e.target.value)}>
                                    <option value="" color={textColor}>Select Discount Parcentage</option>
                                    {
                                        [0, 5, 10, 15, 20].map((item) => (
                                            <option key={item} value={item} color={textColor}>{item} %</option>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </ModalBody>

                        <ModalFooter gap={5}>
                            <Button type='reset' colorScheme='cyan'> Reset </Button>
                            <Button colorScheme='teal' type='submit' isLoading={isLoading} onClick={() => CompleteGenrate()}> Submit </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
