import axios from "../../config/axios"
import { DELETE_PRODUCT_FAILED, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, GET_ALL_PRODUCT_FAILED, GET_ALL_PRODUCT_REQUEST, GET_ALL_PRODUCT_SUCCESS, TOOGLE_PRODUCT_FAILED, TOOGLE_PRODUCT_REQUEST, TOOGLE_PRODUCT_SUCCESS } from "../constants/ProductConstant"

export const GetAllProductAction = (data) => (dispatch) => {
    try {
        dispatch({ type: GET_ALL_PRODUCT_REQUEST })
        axios.get(`/api/color/get-all-color`, { params: data }).then(({ data }) => {
            if (data.success) {
                dispatch({ type: GET_ALL_PRODUCT_SUCCESS, payload: data })
            } else {
                dispatch({ type: GET_ALL_PRODUCT_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: GET_ALL_PRODUCT_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: GET_ALL_PRODUCT_FAILED, payload: error.toString() })
    }
}

export const GetAllActiveProductAction = () => (dispatch) => {
    try {
        dispatch({ type: GET_ALL_PRODUCT_REQUEST })
        axios.get(`/api/color/get-all-active-color`).then(({ data }) => {
            if (data.success) {
                dispatch({ type: GET_ALL_PRODUCT_SUCCESS, payload: data })
            } else {
                dispatch({ type: GET_ALL_PRODUCT_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: GET_ALL_PRODUCT_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: GET_ALL_PRODUCT_FAILED, payload: error.toString() })
    }
}

export const ToogleProductAction = (data) => (dispatch) => {
    try {
        dispatch({ type: TOOGLE_PRODUCT_REQUEST })
        axios.patch(`/api/color/toogle-color/${data}`).then(({ data }) => {
            if (data.success) {
                dispatch({ type: TOOGLE_PRODUCT_SUCCESS, payload: data })
            } else {
                dispatch({ type: TOOGLE_PRODUCT_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: TOOGLE_PRODUCT_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: TOOGLE_PRODUCT_FAILED, payload: error.toString() })
    }
}

export const DeleteProductAction = (data) => (dispatch) => {
    try {
        dispatch({ type: DELETE_PRODUCT_REQUEST })
        axios.delete(`/api/color/delete-color/${data}`).then(({ data }) => {
            if (data.success) {
                dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: data })
            } else {
                dispatch({ type: DELETE_PRODUCT_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: DELETE_PRODUCT_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: DELETE_PRODUCT_FAILED, payload: error.toString() })
    }
}