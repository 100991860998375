import React, { useState } from 'react'
import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, FormControl, FormLabel, Input, useColorModeValue, Button, useToast } from '@chakra-ui/react'
import axios from '../../config/axios'
import { GetSingleWorkAction } from '../../redux/actions/WorkAction'
import { useDispatch } from 'react-redux'

export default function AddPhotoModal({ workid, children }) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [isLoading, setisLoading] = useState(false)
    const [image, setimage] = useState(null)

    const textColor = useColorModeValue("secondaryGray", "white");
    const toast = useToast()
    const dispatch = useDispatch()

    const onSubmit = (event) => {
        event.preventDefault()

        if (image === null) {
            toast({ title: 'Select a image', status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }

        const formdata = new FormData()

        formdata.append('workimage', image)
        formdata.append('workid', workid)

        axios.post(`/api/work/upload-image`, formdata).then(({ data }) => {
            setisLoading(false)
            if (data.success) {
                toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
                dispatch(GetSingleWorkAction(workid))
                onClose()
                setimage(null)
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            setisLoading(false)
            toast({ title: error.response.data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        })
    }

    return (
        <>
            {
                children && (<span onClick={onOpen}>{children}</span>)
            }

            <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered motionPreset='slideInBottom'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Photo</ModalHeader>
                    <ModalCloseButton />
                    <form>
                        <ModalBody pb={6}>
                            <FormControl mb={10}>
                                <FormLabel>Work Image</FormLabel>
                                <Input type='file' color={textColor} onChange={(e) => setimage(e.target.files[0])} />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter gap={5}>
                            <Button type='reset' colorScheme='cyan' onClick={() => setimage(null)}> Reset </Button>
                            <Button colorScheme='teal' type='submit' isLoading={isLoading} onClick={(e) => onSubmit(e)}> Submit </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
