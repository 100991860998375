import React, { useState } from 'react'
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, ModalContent, useColorModeValue, Checkbox, FormHelperText, useToast } from '@chakra-ui/react'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from '../../config/axios';
import { useDispatch } from 'react-redux'
import { GetAllEmployeeAction } from '../../redux/actions/EmployeeAction';
import PhoneInput from 'react-phone-input-2'

export default function AddEmployee({ children, limit, page }) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [isLoading, setisLoading] = useState(false)

    const textColor = useColorModeValue("secondaryGray", "white");
    const toast = useToast()
    const dispatch = useDispatch()

    const FormSchima = Yup.object().shape({
        isActive: Yup.boolean().required("Please provide product status"),
        fname: Yup.string().required("Please provide employee first name"),
        lname: Yup.string().required("Please provide employee last name"),
        email: Yup.string().required("Please provide employee email id").email("Provide valid email id").test({
            message: 'This email id is already used',
            test: async (value) => {
                let { data } = await axios.get(`/api/auth/check-email/${value}`);
                return data.valid;
            },
        }),
        mobile: Yup.string().required("Please provide employee mobile number").test({
            message: 'This mobile number is already used',
            test: async (value) => {
                let { data } = await axios.get(`/api/auth/check-mobile/?mobile=${value.replace(`91`, '')}&countrycode=91`);
                return data.valid;
            },
        })
    })

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm({ resolver: yupResolver(FormSchima), mode: 'all' })

    const onSubmit = (data) => {
        data = { ...data, mobile: data.mobile.replace(`91`, ''), countrycode: 91 }
        setisLoading(true)
        axios.post(`/api/auth/add-user`, data).then(({ data }) => {
            setisLoading(false)
            if (data.success) {
                toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
                dispatch(GetAllEmployeeAction({ limit, page }))
                onClose()
                reset()
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            setisLoading(false)
            toast({ title: error.response.data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        })
    }

    return (
        <>
            {
                children && (<span onClick={onOpen}>{children}</span>)
            }

            <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered motionPreset='slideInBottom'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Employee</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody pb={6}>
                            <FormControl mb={10}>
                                <FormLabel>Employee First Name</FormLabel>
                                <Input type='text' color={textColor} {...register("fname")} />
                                {errors.fname && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.fname.message}</FormHelperText>}
                            </FormControl>
                            <FormControl mb={10}>
                                <FormLabel>Employee Last Name</FormLabel>
                                <Input type='text' color={textColor} {...register("lname")} />
                                {errors.lname && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.lname.message}</FormHelperText>}
                            </FormControl>
                            <FormControl mb={10}>
                                <FormLabel>Employee Email Id</FormLabel>
                                <Input type='text' color={textColor} {...register("email")} />
                                {errors.email && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.email.message}</FormHelperText>}
                            </FormControl>
                            <FormControl mb={10}>
                                <FormLabel>Employee Mobile Number</FormLabel>
                                <Controller name="mobile" control={control} render={({ field: { onChange, value } }) => (
                                    <PhoneInput country={'in'} value={value} onChange={phone => onChange(phone)} containerClass='chakra-input css-1ixluli' inputStyle={{ width: "100%", height: "100%", background: "transparent", border: "none", color: textColor }} buttonStyle={{ backgroundColor: 'transparent', border: 'none' }} />
                                )} />
                                {errors.mobile && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.mobile.message}</FormHelperText>}
                            </FormControl>

                            <FormControl mb={6}>
                                <Checkbox colorScheme='green' {...register("isActive")}>
                                    This employee is active or not
                                </Checkbox>
                                {errors.isActive && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.isActive.message}</FormHelperText>}
                            </FormControl>
                        </ModalBody>

                        <ModalFooter gap={5}>
                            <Button type='reset' colorScheme='cyan' onClick={() => reset()}> Reset </Button>
                            <Button colorScheme='teal' type='submit' isLoading={isLoading}> Submit </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
