import { DELETE_ROOM_FAILED, DELETE_ROOM_REQUEST, DELETE_ROOM_SUCCESS, GET_ALL_ROOM_FAILED, GET_ALL_ROOM_REQUEST, GET_ALL_ROOM_SUCCESS } from "../constants/RoomConstants";

export const GetAllRoomReducer = (state = { rooms: {} }, action) => {
    switch (action.type) {
        case GET_ALL_ROOM_REQUEST:
            return { ...state, loading: true };

        case GET_ALL_ROOM_SUCCESS:
            return { loading: false, rooms: action.payload };

        case GET_ALL_ROOM_FAILED:
            return { loading: false, roomserror: action.payload };

        default:
            return state;
    }
}

export const DeleteRoomReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_ROOM_REQUEST:
            return { deleteloading: true };

        case DELETE_ROOM_SUCCESS:
            return { deleteloading: false, deleterooms: action.payload };

        case DELETE_ROOM_FAILED:
            return { deleteloading: false, deleteerror: action.payload };

        default:
            return state;
    }
}