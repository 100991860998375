import React from 'react'
import ReactPaginate from 'react-paginate'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { HStack, Select, useColorModeValue } from '@chakra-ui/react'

export default function Pagination({ handlePageClick, pageCount, page, limit, handleLimitChange }) {

    const textColor = useColorModeValue("secondaryGray", "white");
    const textColorSecondary = useColorModeValue("#91a1b1", "secondaryGray");

    return (
        <HStack justifyContent={"flex-end"} alignItems={"center"} gap={"50px"}>
            <Select variant='filled' width={{ "3xl": "4%", "base": "10%" }} value={limit} onChange={handleLimitChange} color={textColor} bgColor={textColorSecondary}>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
            </Select>

            {
                (pageCount > 0) && (
                    <ReactPaginate
                        className='result_pagination'
                        activeClassName="active"
                        disabledClassName="disable"
                        breakLabel="..."
                        nextLabel={<ChevronRightIcon color={"black"} />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={6}
                        pageCount={pageCount}
                        previousLabel={<ChevronLeftIcon color={"black"} />}
                        renderOnZeroPageCount={page}
                    />
                )
            }
        </HStack>
    )
}