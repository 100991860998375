import React, { useEffect } from "react"
import { Routes, Route } from 'react-router-dom'
import AuthLayout from "./layouts/auth"
import { AfterLogin, BeforeLogin, OnBorder } from "./Protection"
import MainLayout from "./layouts/dashboard/MainLayout"
import routes, { authroutes } from "./routes"
import OnBording from "./views/auth/onbording/OnBording"
import { useLocation } from 'react-router-dom'
import { AuthState } from "./context/AuthContext"

export default function App() {

  const location = useLocation()
  const { isAuthorized } = AuthState()

  useEffect(() => {
    if (isAuthorized) {
      sessionStorage.setItem("location", location.pathname)
      if (location.state) {
        sessionStorage.setItem("locationState", JSON.stringify(location.state))
      }
    }
  }, [location, isAuthorized])

  return (
    <Routes>
      <Route element={<BeforeLogin />}>
        <Route element={<AuthLayout />}>
          {
            authroutes.map(({ component: Component, path }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))
          }
        </Route>
      </Route>

      <Route element={<OnBorder />}>
        <Route element={<AuthLayout />}>
          <Route path="/onborerded" element={<OnBording />} />
        </Route>
      </Route>

      <Route element={<AfterLogin />}>
        <Route element={<MainLayout />}>
          {
            routes.map(({ component: Component, path }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))
          }
        </Route>
      </Route>
    </Routes>
  )
}