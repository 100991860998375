import React, { useState } from 'react'
import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';

import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import { SidebarContext } from '../../context/SidebarContext';
import routes from '../../routes';
import FixedPlugin from '../../shared/fixedPlugin/FixedPlugin';

export default function MainLayout(props) {

    const [fixed] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);

    const { onOpen } = useDisclosure();
    const location = useLocation()

    const getActiveRoute = (routes) => {
        let activeRoute = 'Default Brand Text';
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].items);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (location.pathname === routes[i].path) {
                return routes[i].name;
            }
        }
        return activeRoute;
    };

    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbar(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].items);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (window.location.href.indexOf(routes[i].path) !== -1) {
                    return routes[i].secondary;
                }
            }
        }
        return activeNavbar;
    };

    const getActiveNavbarText = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (location.pathname.search(routes[i].path) !== -1) {
                    return routes[i].messageNavbar;
                }
            }
        }
        return activeNavbar;
    };

    return (
        <Box>
            <Box>
                <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
                    <Sidebar routes={routes.filter((items) => (items.isMain === true))} display='none' {...props} />
                    <Box float='right' minHeight='100vh' height='100%' overflow='auto' position='relative' maxHeight='100%' w={{ base: '100%', xl: 'calc( 100% - 290px )' }} maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }} transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)' transitionDuration='.2s, .2s, .35s' transitionProperty='top, bottom, width' transitionTimingFunction='linear, linear, ease'>
                        <Portal>
                            <Box>
                                <Navbar onOpen={onOpen} logoText={'Horizon UI Dashboard PRO'} brandText={getActiveRoute(routes)} secondary={getActiveNavbar(routes)} message={getActiveNavbarText(routes)} fixed={fixed} {...props} />
                            </Box>
                        </Portal>
                        <Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='100vh' pt='50px'>
                            <Outlet />
                        </Box>
                    </Box>
                </SidebarContext.Provider>
            </Box>
            <FixedPlugin />
        </Box>
    )
}
