import React from 'react'
import { SimpleGrid, Text } from '@chakra-ui/react'
import WorkCard from './WorkCard'

export default function WorkTabs({ works, setworkdetails }) {
    return (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "xl": 2, "2xl": 2, "3xl": 6 }} gap='20px' mt={{ base: 6 }} mb='20px'>
                {
                    works && works.works && works.works.length > 0 ? works.works.map((items) => (
                        <WorkCard key={items._id} items={items} setworkdetails={setworkdetails} />
                    )) : (
                        <Text w={"80dvw"} paddingX={20} textAlign={"center"}> No Work Found Add Some Work For Display </Text>
                    )
                }
            </SimpleGrid>
    )
}
