// Chakra Imports
import React from 'react';
import { Avatar, Box, Flex, Icon, Link, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue } from '@chakra-ui/react';
import { FaEthereum } from 'react-icons/fa';
import { useDispatch } from 'react-redux'
import { logoutaction } from '../../redux/actions/AuthAction';
import { SidebarResponsive } from '../sidebar/Sidebar';
import routes from '../../routes';
import { AuthState } from '../../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom'

export default function Navbar({ secondary, message, brandText }) {

	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
	let mainText = useColorModeValue('navy.700', 'white');
	// let secondaryText = useColorModeValue('gray.700', 'white');   ?? Imports Breadcrumb, BreadcrumbItem, BreadcrumbLink,
	let navbarPosition = 'fixed';
	let navbarFilter = 'none';
	let navbarBackdrop = 'blur(20px)';
	let navbarShadow = 'none';
	let navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
	let navbarBorder = 'transparent';
	let secondaryMargin = '0px';
	let paddingX = '15px';
	let gap = '0px';


	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.18)', '14px 17px 40px 4px rgba(112, 144, 176, 0.06)');

	const dispatch = useDispatch()
	const { details } = AuthState()

	const navigate = useNavigate()
	const location = useLocation()

	return (
		<Box position={navbarPosition} boxShadow={navbarShadow} bg={navbarBg} borderColor={navbarBorder} filter={navbarFilter} backdropFilter={navbarBackdrop} backgroundPosition='center' backgroundSize='cover' borderRadius='16px' borderWidth='1.5px' borderStyle='solid' transitionDelay='0s, 0s, 0s, 0s' transitionDuration=' 0.25s, 0.25s, 0.25s, 0s' transition-property='box-shadow, background-color, filter, border' transitionTimingFunction='linear, linear, linear, linear'
			alignItems={{ xl: 'center' }} display={secondary ? 'block' : 'flex'} minH='75px' justifyContent={{ xl: 'center' }} lineHeight='25.6px' mx='auto' mt={secondaryMargin} pb='8px'
			right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }} px={{ sm: paddingX, md: '10px' }} ps={{ xl: '12px' }} pt='8px' top={{ base: '12px', md: '16px', lg: '20px', xl: '20px' }} w={{ base: 'calc(100vw - 6%)', md: 'calc(100vw - 8%)', lg: 'calc(100vw - 6%)', xl: 'calc(100vw - 350px)', '2xl': 'calc(100vw - 365px)' }}>

			<Flex w='100%' flexDirection={{ sm: 'column', md: 'row' }} alignItems={{ xl: 'center' }} mb={gap}>
				<Box mb={{ sm: '8px', md: '0px' }}>
					{/* <Breadcrumb>
						<BreadcrumbItem color={secondaryText} fontSize='sm' mb='5px'>
							<BreadcrumbLink href='#' color={secondaryText}>
								{location.pathname.slice(1)}
							</BreadcrumbLink>
						</BreadcrumbItem>
					</Breadcrumb> */}

					{/* Here we create navbar brand, based on route name */}
					<Link color={mainText} href={location.pathname} bg='inherit' borderRadius='inherit' fontWeight='bold' fontSize='34px' _hover={{ color: { mainText } }} _active={{ bg: 'inherit', transform: 'none', borderColor: 'transparent' }} _focus={{ boxShadow: 'none' }}>
						{brandText}
					</Link>
				</Box>
				<Box ms='auto' w={{ sm: '100%', md: 'unset' }}>

					<Flex w={{ sm: '100%', md: 'auto' }} alignItems="center" flexDirection="row" bg={menuBg} flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'} p="10px" borderRadius="30px" boxShadow={shadow}>
						<Flex bg={ethBg} display={secondary ? 'flex' : 'none'} borderRadius="30px" ms="auto" p="6px" align="center" me="6px">
							<Flex align="center" justify="center" bg={ethBox} h="29px" w="29px" borderRadius="30px" me="7px">
								<Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
							</Flex>
							<Text w="max-content" color={ethColor} fontSize="sm" fontWeight="700" me="6px">
								1,924
								<Text as="span" display={{ base: 'none', md: 'unset' }}>
									{' '}
									ETH
								</Text>
							</Text>
						</Flex>
						<SidebarResponsive routes={routes} />

						<Menu>
							<MenuButton p="0px">
								<Avatar _hover={{ cursor: 'pointer' }} color="white" name={details?.fname + " " + details?.lname} bg="#11047A" size="sm" w="40px" h="40px" />
							</MenuButton>
							<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
								<Flex w="100%" mb="0px">
									<Text ps="20px" pt="16px" pb="10px" w="100%" borderBottom="1px solid" borderColor={borderColor} fontSize="sm" fontWeight="700" color={textColor}>
										👋&nbsp; Hey, {details?.fname}
									</Text>
								</Flex>
								<Flex flexDirection="column" p="10px">
									<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px" onClick={() => navigate("/profile-details")}>
										<Text fontSize="sm">Profile Settings</Text>
									</MenuItem>
									<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} color="red.400" borderRadius="8px" px="14px" onClick={() => dispatch(logoutaction())}>
										<Text fontSize="sm">Log out</Text>
									</MenuItem>
								</Flex>
							</MenuList>
						</Menu>
					</Flex>

				</Box>
			</Flex>
			{secondary ? <Text color='white'>{message}</Text> : null}
		</Box>
	);
}
