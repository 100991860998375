/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, ModalOverlay, ModalContent, useColorModeValue, FormHelperText, useToast, Select, Accordion, AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel, Flex } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MdArrowDropDown } from 'react-icons/md';
import axios from '../../config/axios'
import { GetAllRoomsReducer } from '../../redux/actions/RoomAction'
import { GetAllActiveProductAction } from '../../redux/actions/ProductAction'

export default function EditRoom({ quotation_id, roomdetails, setroomdetails }) {

    const [isLoading, setisLoading] = useState(false)
    const [adddeduction, setadddeduction] = useState({ celling: false, wall: false })
    const [producttype, setproducttype] = useState({ celling: "", wall: "" })
    const [grandtotal, setgrandtotal] = useState({ feet: 0, price: 0 })

    const textColor = useColorModeValue("secondaryGray", "white");
    const toast = useToast()
    const dispatch = useDispatch()

    useMemo(() => dispatch(GetAllActiveProductAction()), [dispatch])

    const { products } = useSelector((state) => state.getproduct)

    const FormSchima = Yup.object().shape({
        roomname: Yup.string().required('Provide room name'),

        wallstartfeet: Yup.string().required('Provide wall start feet'),
        wallendfeet: Yup.string().required('Provide wall end feet'),
        wallcolor: Yup.string().required('Provide wall color'),
        wallprice: Yup.string().required('Provide wall costing'),
        walltotalprice: Yup.string().required('Provide wall total costing'),
        walltotalfeet: Yup.string().required('Provide wall total feet'),
        walladddeduction: Yup.boolean().required('Provide wall deduction type'),
        wallremovestartfeet: Yup.string().when("walladddeduction", {
            is: true,
            then: schema => schema.required("Provide wall removed start feet"),
            otherwise: schema => schema.notRequired()
        }),
        wallremoveendfeet: Yup.string().when("walladddeduction", {
            is: true,
            then: schema => schema.required("Provide wall removed start feet"),
            otherwise: schema => schema.notRequired()
        }),
        wallothercolor: Yup.string().when("wallcolor", {
            is: (wallcolor) => "others" === wallcolor,
            then: schema => schema.required("Provide wall color name"),
            otherwise: schema => schema.notRequired()
        }),

        cellingstartfeet: Yup.string().required('Provide celling start feet'),
        cellingendfeet: Yup.string().required('Provide celling end feet'),
        cellingcolor: Yup.string().required('Provide celling color'),
        cellingprice: Yup.string().required('Provide celling costing'),
        cellingtotalprice: Yup.string().required('Provide celling total costing'),
        cellingtotalfeet: Yup.string().required('Provide celling total feet'),
        cellingadddeduction: Yup.boolean().required('Provide celling deduction type'),
        cellingremovestartfeet: Yup.string().when("cellingadddeduction", {
            is: true,
            then: schema => schema.required("Provide celling removed start feet"),
            otherwise: schema => schema.notRequired()
        }),
        cellingremoveendfeet: Yup.string().when("cellingadddeduction", {
            is: true,
            then: schema => schema.required("Provide celling removed start feet"),
            otherwise: schema => schema.notRequired()
        }),
        cellingothercolor: Yup.string().when("cellingcolor", {
            is: (cellingcolor) => "others" === cellingcolor,
            then: schema => schema.required("Provide celling color name"),
            otherwise: schema => schema.notRequired()
        })
    })

    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues, } = useForm({ resolver: yupResolver(FormSchima), mode: 'all' })

    useEffect(() => {
        setValue("roomname", roomdetails?.roomname)
        // Celling Section
        setValue("cellingstartfeet", roomdetails?.celling?.feet?.from)
        setValue("cellingendfeet", roomdetails?.celling?.feet?.to)
        if (!roomdetails?.celling?.color?._id) {
            setValue("cellingcolor", "others")
        } else {
            setValue("cellingcolor", roomdetails?.celling?.color?._id)
        }

        setValue("cellingprice", roomdetails?.celling?.colorprice)
        setValue("cellingtotalfeet", roomdetails?.celling?.totalfeet)
        setValue("cellingtotalprice", roomdetails?.celling?.price)
        setValue("cellingothercolor", roomdetails?.celling?.othercolor)
        if ((roomdetails?.celling?.removedfeet?.from > 0) && (roomdetails?.celling?.removedfeet?.to > 0)) {
            setValue("cellingadddeduction", true)
            setadddeduction({ ...adddeduction, celling: true })
            setValue("cellingremovestartfeet", roomdetails?.celling?.removedfeet?.from)
            setValue("cellingremoveendfeet", roomdetails?.celling?.removedfeet?.to)
        } else {
            setValue("cellingadddeduction", false)
        }
        // Wall Section
        setValue("wallstartfeet", roomdetails?.wall?.feet?.from)
        setValue("wallendfeet", roomdetails?.wall?.feet?.to)
        if (!roomdetails?.wall?.color?._id) {
            setValue("wallcolor", "others")
        } else {
            setValue("wallcolor", roomdetails?.wall?.color?._id)
        }

        setValue("wallprice", roomdetails?.wall?.colorprice)
        setValue("walltotalfeet", roomdetails?.wall?.totalfeet)
        setValue("walltotalprice", roomdetails?.wall?.price)
        setValue("wallothercolor", roomdetails?.wall?.othercolor)
        if ((roomdetails?.wall?.removedfeet?.from > 0) && (roomdetails?.wall?.removedfeet?.to > 0)) {
            setadddeduction({ ...adddeduction, wall: true })
            setValue("walladddeduction", true)
            setValue("wallremovestartfeet", roomdetails?.wall?.removedfeet?.from)
            setValue("wallremoveendfeet", roomdetails?.wall?.removedfeet?.to)
        } else {
            setValue("walladddeduction", false)
        }
        setproducttype({ celling: roomdetails?.celling?.color?._id ? roomdetails?.celling?.color?._id : 'others', wall: roomdetails?.wall?.color?._id ? roomdetails?.wall?.color?._id : 'others' })
        setgrandtotal({
            feet: (roomdetails?.celling?.totalfeet + roomdetails?.wall?.totalfeet),
            price: (roomdetails?.celling?.price + roomdetails?.wall?.price)
        })
    }, [])

    const getDetails = () => {

        let totalwallremovefeet = ((getValues('wallremovestartfeet') ? Number(getValues('wallremovestartfeet')) : 0) * (getValues('wallremoveendfeet') ? Number(getValues('wallremoveendfeet')) : 0));
        let totalwallfeet = ((getValues('wallstartfeet') ? Number(getValues('wallstartfeet')) : 0) * (getValues('wallendfeet') ? Number(getValues('wallendfeet')) : 0));

        let totalcellingremovefeet = ((getValues('cellingremovestartfeet') ? Number(getValues('cellingremovestartfeet')) : 0) * (getValues('cellingremoveendfeet') ? Number(getValues('cellingremoveendfeet')) : 0));
        let totalcellingfeet = ((getValues('cellingstartfeet') ? Number(getValues('cellingstartfeet')) : 0) * (getValues('cellingendfeet') ? Number(getValues('cellingendfeet')) : 0));

        setValue("walltotalfeet", Number(totalwallfeet - totalwallremovefeet))
        setValue("walltotalprice", Number((getValues('wallprice') ? Number(getValues('wallprice')) : 0) * Number(totalwallfeet - totalwallremovefeet)))

        setValue("cellingtotalfeet", Number(totalcellingfeet - totalcellingremovefeet))
        setValue("cellingtotalprice", Number((getValues('cellingprice') ? Number(getValues('cellingprice')) : 0) * Number(totalcellingfeet - totalcellingremovefeet)))

        setgrandtotal({
            price: Number(Number((getValues('wallprice') ? Number(getValues('wallprice')) : 0) * Number(totalwallfeet - totalwallremovefeet)) + Number((getValues('cellingprice') ? Number(getValues('cellingprice')) : 0) * Number(totalcellingfeet - totalcellingremovefeet))),
            feet: Number(Number(totalwallfeet - totalwallremovefeet) + Number(totalcellingfeet - totalcellingremovefeet))
        })

    };

    const resetfunction = () => {
        reset();
        setroomdetails(null);
        setadddeduction({ celling: false, wall: false })
        setproducttype({ celling: "", wall: "" })
        setgrandtotal({ feet: 0, price: 0 })
    }


    const onSubmit = (data) => {
        let structure = {
            // Wall Section
            roomname: data.roomname,
            feet: {
                from: Number(data.wallstartfeet),
                to: Number(data.wallendfeet)
            },
            totalfeet: Number(data.walltotalfeet),
            colorprice: Number(data.wallprice),
            price: Number(data.walltotalprice),
            // Celling Section
            cellingfeet: {
                from: Number(data.cellingstartfeet),
                to: Number(data.cellingendfeet)
            },
            cellingtotalfeet: Number(data.cellingtotalfeet),
            cellingcolorprice: data.cellingprice,
            cellingprice: Number(data.cellingtotalprice),
        }

        if (data.wallremovestartfeet && data.wallremoveendfeet) {
            Object.assign(structure, { removedfeet: { from: Number(data.wallremovestartfeet), to: Number(data.wallremoveendfeet) } })
        }

        if (data.cellingremovestartfeet && data.cellingremoveendfeet) {
            Object.assign(structure, { cellingremovedfeet: { from: Number(data.cellingremovestartfeet), to: Number(data.cellingremoveendfeet) } })
        }

        if (data.wallothercolor) {
            structure.othercolor = data.wallothercolor;
        } else {
            structure.color = data.wallcolor;
        }

        if (data.cellingothercolor) {
            structure.cellingothercolor = data.cellingothercolor;
        } else {
            structure.cellingcolor = data.cellingcolor;
        }

        setisLoading(true)

        axios.put(`/api/quotation/edit-room/${quotation_id}/${roomdetails._id}`, structure).then(({ data }) => {
            setisLoading(false);
            if (data.success) {
                toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
                dispatch(GetAllRoomsReducer(quotation_id))
                resetfunction()
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            setisLoading(false);
            toast({ title: error.response ? error.response.data.message : error.toString(), status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        });
    }

    return (
        <>
            <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={() => resetfunction()} isOpen={true} isCentered motionPreset='slideInBottom' size={"xl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Update Room</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody pb={6}>
                            <FormControl mb={10}>
                                <FormLabel>Room Name</FormLabel>
                                <Input type='text' color={textColor} {...register("roomname")} />
                                {errors.roomname && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.roomname.message}</FormHelperText>}
                            </FormControl>

                            <Accordion defaultIndex={[0]}>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left'>
                                                Celling
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Flex w={"100%"} justify={"space-between"} align={"center"}>
                                            <FormControl mb={10} w={"40%"}>
                                                <Flex justify={"center"} align={"center"}>
                                                    <FormLabel>L</FormLabel>
                                                    <Input type='number' color={textColor} {...register("cellingstartfeet", { onChange: () => getDetails() })} />
                                                </Flex>
                                                {errors.cellingstartfeet && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.cellingstartfeet.message}</FormHelperText>}
                                            </FormControl>
                                            <FormControl mb={10} w={"40%"}>
                                                <Flex justify={"center"} align={"center"}>
                                                    <FormLabel>W</FormLabel>
                                                    <Input type='number' color={textColor} {...register("cellingendfeet", { onChange: () => getDetails() })} />
                                                </Flex>
                                                {errors.cellingendfeet && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.cellingendfeet.message}</FormHelperText>}
                                            </FormControl>
                                        </Flex>

                                        <FormControl mb={10}>
                                            <FormLabel>Product Type</FormLabel>
                                            <Select variant='outline' color={textColor} icon={<MdArrowDropDown />} {...register("cellingcolor", { onChange: (e) => setproducttype({ ...producttype, celling: e.target.value }) })}>
                                                <option value="" color={textColor}>Select Product Type</option>
                                                {
                                                    products && products.color && products.color.map((item) => (
                                                        <option key={item._id} value={item._id} color={textColor}>{item.header.toLocaleUpperCase() + ' - ' + item.subheader.toLocaleUpperCase()}</option>
                                                    ))
                                                }
                                                <option value="others" color={textColor}>Others</option>
                                            </Select>
                                            {errors.cellingcolor && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.cellingcolor.message}</FormHelperText>}
                                        </FormControl>
                                        {
                                            producttype.celling === "others" && (
                                                <FormControl mb={10}>
                                                    <FormLabel>Other Product Type</FormLabel>
                                                    <Input type='text' color={textColor} {...register("cellingothercolor")} />
                                                    {errors.cellingothercolor && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.cellingothercolor.message}</FormHelperText>}
                                                </FormControl>
                                            )
                                        }

                                        <FormControl mb={10}>
                                            <FormLabel>Painting Costing</FormLabel>
                                            <Input type='number' color={textColor} {...register("cellingprice", { onChange: () => getDetails() })} />
                                            {errors.cellingprice && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.cellingprice.message}</FormHelperText>}
                                        </FormControl>


                                        {
                                            adddeduction.celling ? (
                                                <Flex w={"100%"} justify={"space-between"} align={"center"}>
                                                    <FormControl mb={10} w={"40%"}>
                                                        <Flex justify={"center"} align={"center"}>
                                                            <FormLabel>L</FormLabel>
                                                            <Input type='number' color={textColor} {...register("cellingremovestartfeet", { onChange: () => getDetails() })} />
                                                        </Flex>
                                                        {errors.cellingremovestartfeet && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.cellingremovestartfeet.message}</FormHelperText>}
                                                    </FormControl>
                                                    <FormControl mb={10} w={"40%"}>
                                                        <Flex justify={"center"} align={"center"}>
                                                            <FormLabel>W</FormLabel>
                                                            <Input type='number' color={textColor} {...register("cellingremoveendfeet", { onChange: () => getDetails() })} />
                                                        </Flex>
                                                        {errors.cellingremoveendfeet && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.cellingremoveendfeet.message}</FormHelperText>}
                                                    </FormControl>
                                                </Flex>
                                            ) : (
                                                <Button type='button' colorScheme='teal' variant='outline' mb={10} w={"100%"} onClick={() => { setadddeduction({ ...adddeduction, celling: true }); setValue("cellingadddeduction", true) }}> ADD DEDUCTION </Button>
                                            )
                                        }


                                        <Flex justify={"space-between"} align={"center"} gap={10}>
                                            <FormControl mb={10}>
                                                <FormLabel>Celling Total Feet</FormLabel>
                                                <Input type='number' color={textColor} {...register("cellingtotalfeet")} disabled />
                                                {errors.cellingtotalfeet && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.cellingtotalfeet.message}</FormHelperText>}
                                            </FormControl>

                                            <FormControl mb={10}>
                                                <FormLabel>Celling Total Costing</FormLabel>
                                                <Input type='number' color={textColor} {...register("cellingtotalprice")} />
                                                {errors.cellingtotalprice && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.cellingtotalprice.message}</FormHelperText>}
                                            </FormControl>
                                        </Flex>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left'>
                                                Wall
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Flex w={"100%"} justify={"space-between"} align={"center"}>
                                            <FormControl mb={10} w={"40%"}>
                                                <Flex justify={"center"} align={"center"}>
                                                    <FormLabel>L</FormLabel>
                                                    <Input type='number' color={textColor} {...register("wallstartfeet", { onChange: () => getDetails() })} />
                                                </Flex>
                                                {errors.wallstartfeet && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.wallstartfeet.message}</FormHelperText>}
                                            </FormControl>
                                            <FormControl mb={10} w={"40%"}>
                                                <Flex justify={"center"} align={"center"}>
                                                    <FormLabel>W</FormLabel>
                                                    <Input type='number' color={textColor} {...register("wallendfeet", { onChange: () => getDetails() })} />
                                                </Flex>
                                                {errors.wallendfeet && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.wallendfeet.message}</FormHelperText>}
                                            </FormControl>
                                        </Flex>

                                        <FormControl mb={10}>
                                            <FormLabel>Product Type</FormLabel>
                                            <Select variant='outline' color={textColor} icon={<MdArrowDropDown />} {...register("wallcolor", { onChange: (e) => setproducttype({ ...producttype, wall: e.target.value }) })}>
                                                <option value="" color={textColor}>Select Product Type</option>
                                                {
                                                    products && products.color && products.color.map((item) => (
                                                        <option key={item._id} value={item._id} color={textColor}>{item.header.toLocaleUpperCase() + ' - ' + item.subheader.toLocaleUpperCase()}</option>
                                                    ))
                                                }
                                                <option value="others" color={textColor}>Others</option>
                                            </Select>
                                            {errors.wallcolor && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.wallcolor.message}</FormHelperText>}
                                        </FormControl>
                                        {
                                            producttype.wall === "others" && (
                                                <FormControl mb={10}>
                                                    <FormLabel>Other Product Type</FormLabel>
                                                    <Input type='text' color={textColor} {...register("wallothercolor")} />
                                                    {errors.wallothercolor && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.wallothercolor.message}</FormHelperText>}
                                                </FormControl>
                                            )
                                        }

                                        <FormControl mb={10}>
                                            <FormLabel>Painting Costing</FormLabel>
                                            <Input type='number' color={textColor} {...register("wallprice", { onChange: () => getDetails() })} />
                                            {errors.wallprice && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.wallprice.message}</FormHelperText>}
                                        </FormControl>


                                        {
                                            adddeduction.wall ? (
                                                <Flex w={"100%"} justify={"space-between"} align={"center"}>
                                                    <FormControl mb={10} w={"40%"}>
                                                        <Flex justify={"center"} align={"center"}>
                                                            <FormLabel>L</FormLabel>
                                                            <Input type='number' color={textColor} {...register("wallremovestartfeet", { onChange: () => getDetails() })} />
                                                        </Flex>
                                                        {errors.wallremovestartfeet && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.wallremovestartfeet.message}</FormHelperText>}
                                                    </FormControl>
                                                    <FormControl mb={10} w={"40%"}>
                                                        <Flex justify={"center"} align={"center"}>
                                                            <FormLabel>W</FormLabel>
                                                            <Input type='number' color={textColor} {...register("wallremoveendfeet", { onChange: () => getDetails() })} />
                                                        </Flex>
                                                        {errors.wallremoveendfeet && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.wallremoveendfeet.message}</FormHelperText>}
                                                    </FormControl>
                                                </Flex>
                                            ) : (
                                                <Button type='button' colorScheme='teal' variant='outline' mb={10} w={"100%"} onClick={() => { setadddeduction({ ...adddeduction, wall: true }); setValue("walladddeduction", true) }}> ADD DEDUCTION </Button>
                                            )
                                        }


                                        <Flex justify={"space-between"} align={"center"} gap={10}>
                                            <FormControl mb={10}>
                                                <FormLabel>Celling Total Feet</FormLabel>
                                                <Input type='number' color={textColor} {...register("walltotalfeet")} disabled />
                                                {errors.walltotalfeet && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.walltotalfeet.message}</FormHelperText>}
                                            </FormControl>

                                            <FormControl mb={10}>
                                                <FormLabel>Celling Total Costing</FormLabel>
                                                <Input type='number' color={textColor} {...register("walltotalprice")} />
                                                {errors.walltotalprice && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.walltotalprice.message}</FormHelperText>}
                                            </FormControl>
                                        </Flex>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>

                            <Flex justify={"space-between"} align={"center"} mt={{ base: 5 }} gap={10}>
                                <FormControl mb={10}>
                                    <FormLabel>Grand Total Feet</FormLabel>
                                    <Input type='number' color={textColor} disabled value={grandtotal.feet} />
                                </FormControl>

                                <FormControl mb={10}>
                                    <FormLabel>Grand Total Costing</FormLabel>
                                    <Input type='number' color={textColor} disabled value={grandtotal.price} />
                                </FormControl>
                            </Flex>
                        </ModalBody>

                        <ModalFooter gap={5}>
                            <Button type='reset' colorScheme='cyan' onClick={() => resetfunction()}> Reset </Button>
                            <Button colorScheme='teal' type='submit' isLoading={isLoading}> Submit </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
