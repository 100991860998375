/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { useColorModeValue, Box, SimpleGrid, Text, useToast, Button, Flex, InputGroup, InputLeftAddon, Input, InputRightAddon } from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux'
import { GetAllProductAction } from '../../../redux/actions/ProductAction';
import Loader from '../../../shared/loader/Loader';
import Pagination from '../../../shared/pagination/Pagination';
import ProductCard from '../../../components/productcomponent/ProductCard';
import AddProduct from '../../../components/productcomponent/AddProduct';
import EditProduct from '../../../components/productcomponent/EditProduct';
import { SearchIcon } from '@chakra-ui/icons'

export default function ProductView() {

    const [limit, setlimit] = useState(30)
    const [page, setpage] = useState(1)
    const [productdetails, setproductdetails] = useState(null)
    const [search, setsearch] = useState("")

    const dispatch = useDispatch()

    const textColor = useColorModeValue("secondaryGray", "white");

    let { loading, products, producterror } = useSelector((state) => state.getproduct)
    let { toogleloading, product, toogleerror } = useSelector((state) => state.toogleproduct)
    let { deleteloading, deleteproduct, deleteerror } = useSelector((state) => state.deleteproduct)

    const toast = useToast()

    useMemo(() => dispatch(GetAllProductAction({ limit, page })), [dispatch, limit, page, product, deleteproduct])

    useEffect(() => {
        if (producterror) {
            toast({ title: producterror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }

        if (toogleerror) {
            toast({ title: toogleerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }

        if (deleteerror) {
            toast({ title: deleteerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
    }, [producterror, toogleerror, deleteerror])

    return (
        <>
            {(loading || toogleloading || deleteloading) && <Loader visible={loading || toogleloading || deleteloading} />}
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={{ base: 5 }}>
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                    <form style={{width: '100%'}} onSubmit={(event) => { event.preventDefault(); dispatch(GetAllProductAction({ limit, page, search })); }}>
                        <InputGroup w={{ base: "75%", "3xl": "50%" }}>
                            <InputLeftAddon>
                                <SearchIcon color={"green"} />
                            </InputLeftAddon>
                            <Input type='text' value={search} color={textColor} placeholder='Enter product name or type ...' onChange={(e) => setsearch(e.target.value)} />
                            <InputRightAddon>
                                <Button border={"none"} background={"transparent"} _hover={{ background: "transparent" }} type='submit'> Search </Button>
                                <Text>|</Text>
                                <Button border={"none"} background={"transparent"} _hover={{ background: "transparent" }} onClick={() => { setsearch(""); dispatch(GetAllProductAction({ limit, page, search: "" })) }}> Reset </Button>
                            </InputRightAddon>
                        </InputGroup>
                    </form>

                    <AddProduct limit={limit} page={page}>
                        <Button colorScheme='teal' size='md'> Add Product </Button>
                    </AddProduct>
                </Flex>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "xl": 2, "2xl": 2, "3xl": 6 }} gap='20px' mt={{ base: 6 }} mb='20px'>
                    {
                        products && products.color && products.color.length > 0 ? products.color.map((items) => (
                            <ProductCard imageUrl={items?.image} name={items.header} value={items.subheader} key={items._id} isActive={items.isActive} id={items._id} setproductdetails={setproductdetails} />
                        )) : (
                            <Text w={"80dvw"} paddingX={20} textAlign={"center"}> No Product Found Add Some Product For Display </Text>
                        )
                    }
                </SimpleGrid>

                <SimpleGrid>
                    {
                        products && products.pagination && (
                            <Pagination page={products?.pagination?.page} limit={products?.pagination?.limit} pageCount={products?.pagination?.totalPage} handlePageClick={({ selected }) => setpage(selected + 1)} handleLimitChange={(e) => { setlimit(e.target.value); setpage(1) }} />
                        )
                    }
                </SimpleGrid>

                {
                    (productdetails !== null) && (
                        <EditProduct product={productdetails} limit={limit} page={page} setproductdetails={setproductdetails} />
                    )
                }
            </Box>
        </>
    )
}
