import axios from "../../config/axios"
import { DELETE_WORK_FAILED, DELETE_WORK_REQUEST, DELETE_WORK_SUCCESS, GET_ALL_WORK_FAILED, GET_ALL_WORK_REQUEST, GET_ALL_WORK_SUCCESS, GET_SINGLE_WORK_FAILED, GET_SINGLE_WORK_REQUEST, GET_SINGLE_WORK_SUCCESS, TOOGLE_WORK_FAILED, TOOGLE_WORK_REQUEST, TOOGLE_WORK_SUCCESS } from "../constants/WorkConstance"

export const GetAllWorkAction = (data) => (dispatch) => {
    try {
        dispatch({ type: GET_ALL_WORK_REQUEST })
        axios.get(`/api/work/all-work`, { params: data }).then(({ data }) => {
            if (data.success) {
                dispatch({ type: GET_ALL_WORK_SUCCESS, payload: data })
            } else {
                dispatch({ type: GET_ALL_WORK_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: GET_ALL_WORK_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: GET_ALL_WORK_FAILED, payload: error.toString() })
    }
}

export const GetSingleWorkAction = (data) => (dispatch) => {
    try {
        dispatch({ type: GET_SINGLE_WORK_REQUEST })
        axios.get(`/api/work/get-single-work/${data}`).then(({ data }) => {
            if (data.success) {
                dispatch({ type: GET_SINGLE_WORK_SUCCESS, payload: data })
            } else {
                dispatch({ type: GET_SINGLE_WORK_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: GET_SINGLE_WORK_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: GET_SINGLE_WORK_FAILED, payload: error.toString() })
    }
}

export const ToogleWorkAction = (data) => (dispatch) => {
    try {
        dispatch({ type: TOOGLE_WORK_REQUEST })
        axios.patch(`/api/work/toogle-work/${data}`).then(({ data }) => {
            if (data.success) {
                dispatch({ type: TOOGLE_WORK_SUCCESS, payload: data })
            } else {
                dispatch({ type: TOOGLE_WORK_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: TOOGLE_WORK_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: TOOGLE_WORK_FAILED, payload: error.toString() })
    }
}

export const DeleteWorkAction = (data) => (dispatch) => {
    try {
        dispatch({ type: DELETE_WORK_REQUEST })
        axios.delete(`/api/work/delete-work/${data}`).then(({ data }) => {
            if (data.success) {
                dispatch({ type: DELETE_WORK_SUCCESS, payload: data })
            } else {
                dispatch({ type: DELETE_WORK_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: DELETE_WORK_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: DELETE_WORK_FAILED, payload: error.toString() })
    }
}