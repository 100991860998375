import React from 'react'
import { DragHandleIcon } from '@chakra-ui/icons'
import { MdDeleteOutline, MdEdit, MdOutlineCalendarMonth, MdOutlineLocationOn, MdOutlinePerson, MdOutlinePhone, MdOutlineToggleOff, MdOutlineToggleOn } from 'react-icons/md';
import { Flex, Stat, StatLabel, StatNumber, useColorModeValue, Menu, MenuButton, Button, MenuList, MenuItem, Icon, Badge, StatHelpText, HStack } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import Card from '../../shared/card/Card';
import { DeleteWorkAction, ToogleWorkAction } from '../../redux/actions/WorkAction';
import moment from 'moment';
import { useNavigate } from 'react-router-dom'
import { AuthState } from '../../context/AuthContext';

export default function WorkCard({ setworkdetails, items }) {

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "secondaryGray.800";

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { details } = AuthState()

    return (
        <Card py='15px' border={"1px solid"} borderColor={items?.isActive ? "#27ff00" : "#ff2f00"} cursor="pointer">
            <Flex my='auto' h='100%' align={{ base: "center", xl: "center" }} justify={{ base: "center", xl: "center" }}>
                <Stat my='auto' ms={"0px"} gap={{ base: 5 }} onClick={() => navigate("/works-list/quotation-list", { state: { workid: items._id } })}>
                    <StatNumber color={textColor} fontSize={{ base: "2xl", }}>
                        {items?.customerName}
                    </StatNumber>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} w={{ base: "100%", sm: "90%" }} mt={{ base: 2 }}>
                        <Icon as={MdOutlinePhone} width='20px' height='20px' color='inherit' />
                        {"+" + items?.customercountrycode + " - " + items?.customermobile}
                    </StatLabel>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                        <Icon as={MdOutlineLocationOn} width='20px' height='20px' color='inherit' />
                        {items?.customerAddress}
                    </StatLabel>

                    <StatNumber color={textColor} fontSize={{ base: "2xl", }} mt={{ base: 5 }}>
                        {items?.projectName}
                    </StatNumber>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                        <Icon as={MdOutlineLocationOn} width='20px' height='20px' color='inherit' />
                        {items?.location}
                    </StatLabel>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} w={{ base: "100%", sm: "90%" }} mt={{ base: 2 }}>
                        Consultation Status :  {items?.consultationStatus}
                    </StatLabel>
                </Stat>

                <Flex ms='auto' w='max-content' direction={"column"} gap={{ base: 10 }}>
                    <Menu>
                        <MenuButton as={Button}>
                            <DragHandleIcon />
                        </MenuButton>
                        <MenuList>
                            <MenuItem gap={2} onClick={() => navigate("/works-list/seals-person", { state: { workid: items._id } })}><Icon as={MdOutlinePerson} width='20px' height='20px' color='inherit' /> Select Seals Person</MenuItem>
                            <MenuItem gap={2} onClick={() => setworkdetails(items)}><Icon as={MdEdit} width='20px' height='20px' color='inherit' /> Edit Work</MenuItem>
                            <MenuItem gap={2} onClick={() => dispatch(ToogleWorkAction(items._id))}><Icon as={items?.isActive ? MdOutlineToggleOff : MdOutlineToggleOn} width='20px' height='20px' color='inherit' /> Toogle Work</MenuItem>
                            <MenuItem gap={2} onClick={() => dispatch(DeleteWorkAction(items._id))}><Icon as={MdDeleteOutline} width='20px' height='20px' color='inherit' /> Delete Work</MenuItem>
                        </MenuList>
                    </Menu>
                    <Badge px={5} py={2} colorScheme={(items.workStatus === "pending") ? 'purple' : (items.workStatus === "completed") ? 'green' : 'blue'}>{items.workStatus}</Badge>
                </Flex>
            </Flex>
            <Flex my='auto' h='100%' align={{ base: "flex-end", xl: "flex-end" }} justify={{ base: "center", xl: "center" }} mt={{ base: 5 }} w={"100%"}>
                {
                    (details?.role !== "employee") && (
                        <Stat ms={"0px"} gap={{ base: 5 }}>
                            <HStack justifyContent={"center"} alignItems={"center"}>
                                <StatNumber lineHeight='100%' color={textColor} fontSize={{ base: "sm", }} columnGap={{ base: 2 }}> Selas Person : </StatNumber>
                                <StatHelpText whiteSpace={"nowrap"} mb={0}>{items?.salesPerson && items?.salesPerson?.fname + " " + items?.salesPerson?.lname}</StatHelpText>
                            </HStack>
                        </Stat>
                    )
                }
                <Stat ms={"0px"} gap={{ base: 5 }}>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                        <Icon as={MdOutlineCalendarMonth} width='20px' height='20px' color='inherit' />
                        {moment(items?.consultationDate).format("DD-MM-YYYY HH:mm")}
                    </StatLabel>
                </Stat>
            </Flex>
        </Card>
    )
}
