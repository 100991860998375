/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Box, SimpleGrid, Text, useToast, Button, Flex, Stat, useColorModeValue, StatNumber, Badge } from "@chakra-ui/react";
import RoomCard from '../../../components/roomcomponent/RoomCard';
import { GetAllRoomsReducer } from '../../../redux/actions/RoomAction';
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../shared/loader/Loader';
import AddRoom from '../../../components/roomcomponent/AddRoom';
import EditRoom from '../../../components/roomcomponent/EditRoom';
import DiscoundSection from '../../../components/roomcomponent/DiscoundSection';

export default function RoomView() {

    const textColor = useColorModeValue("secondaryGray.900", "white");

    const location = useLocation()

    const [roomdetails, setroomdetails] = useState(null)

    const dispatch = useDispatch()
    const toast = useToast()

    let { loading, rooms, roomserror } = useSelector((state) => state.getroom)
    let { deleteloading, deleterooms, deleteerror } = useSelector((state) => state.deleteroom)

    useMemo(() => dispatch(GetAllRoomsReducer(location.state.quotation_id)), [dispatch, location, deleterooms])

    useEffect(() => {
        if (roomserror) {
            toast({ title: roomserror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
        if (deleteerror) {
            toast({ title: deleteerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
    }, [roomserror, deleteerror])

    return (
        <>
            {(loading || deleteloading) && <Loader visible={loading || deleteloading} />}
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={{ base: 5 }}>
                <Flex alignItems={"center"} justifyContent={"flex-end"} gap={10}>
                    {
                        rooms?.rooms?.pdfurl === "" ? (
                            <>
                                <AddRoom quotation_id={location.state.quotation_id}>
                                    <Button colorScheme='teal' size='md'> Add Room </Button>
                                </AddRoom>
                                {
                                    rooms && rooms.rooms && rooms.rooms.rooms && rooms.rooms.rooms.length > 0 && (
                                        <DiscoundSection quotationid={location.state.quotation_id} workid={rooms?.rooms?.work}>
                                            <Button colorScheme='teal' size='md'> Generate PDF </Button>
                                        </DiscoundSection>
                                    )
                                }
                            </>
                        ) : (
                            <Link className='chakra-button btn css-135569u' colorScheme='teal' size='md' to={rooms?.rooms?.pdfurl} target='_blank'> Download PDF </Link>
                        )
                    }
                </Flex>
                <Flex alignItems={"center"} justifyContent={"center"} w={"100%"}>
                    <Badge mt={{ base: 5 }} py='15px' border={"1px solid"} borderColor={rooms?.rooms?.isActive ? "#27ff00" : "#ff2f00"} cursor="pointer">
                        <Stat my='auto' ms={"0px"}>
                            <Flex justifyContent={"center"} gap={{ base: 20 }} alignItems={"center"}>
                                <StatNumber color={textColor} fontSize={{ base: "small", }}>
                                    Grand Total : {rooms?.rooms?.grandtotal}
                                </StatNumber>
                                <StatNumber color={textColor} fontSize={{ base: "small", }}>
                                    Discount Total : {rooms?.rooms?.discounttotal}
                                </StatNumber>
                                <StatNumber color={textColor} fontSize={{ base: "small", }}>
                                    Grand Feet : {rooms?.rooms?.grandfeet}
                                </StatNumber>
                                <StatNumber color={textColor} fontSize={{ base: "small", }}>
                                    Discount Percentage : {rooms?.rooms?.discount} %
                                </StatNumber>
                            </Flex>
                        </Stat>
                    </Badge>
                </Flex>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "xl": 2, "2xl": 2, "3xl": 6 }} gap='20px' mt={{ base: 6 }} mb='20px'>
                    {
                        rooms && rooms.rooms && rooms.rooms.rooms && rooms.rooms.rooms.length > 0 ? rooms.rooms.rooms.map((items) => (
                            <RoomCard items={items} key={items._id} setroomdetails={setroomdetails} quotation={location.state.quotation_id} pdfurl={rooms?.rooms?.pdfurl} />
                        )) : (
                            <Text w={"80dvw"} paddingX={20} textAlign={"center"}> No Room Found Add Some Room For Display </Text>
                        )
                    }
                </SimpleGrid>

                {
                    (roomdetails !== null) && (
                        <EditRoom roomdetails={roomdetails} setroomdetails={setroomdetails} quotation_id={location.state.quotation_id} />
                    )
                }
            </Box>
        </>
    )
}
