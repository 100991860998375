import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, Image } from '@chakra-ui/react'

export default function ViewPhotosComponents({ imageUrl, show, setShow }) {

    return (
        <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={() => setShow(!show)} isOpen={show} isCentered motionPreset='slideInBottom' size={"xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>View Photo</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Image src={imageUrl} objectFit={"contain"} />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
