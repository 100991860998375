import React from 'react'
import { DragHandleIcon } from '@chakra-ui/icons'
import { MdEdit, MdOutlineEmail, MdOutlinePassword, MdOutlinePhone, MdOutlineToggleOff, MdOutlineToggleOn } from 'react-icons/md';
import { Flex, Stat, StatLabel, StatNumber, useColorModeValue, Menu, MenuButton, Button, MenuList, MenuItem, Icon } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import Card from '../../shared/card/Card';
import { ToogleEmployeeAction } from '../../redux/actions/EmployeeAction';

export default function EmployeeCard({ name, email, mobile, setemployeedetails, items }) {

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "secondaryGray.800";

    const dispatch = useDispatch()

    return (
        <Card py='15px' border={"1px solid"} borderColor={items?.isActive ? "#27ff00" : "#ff2f00"}>
            <Flex my='auto' h='100%' align={{ base: "center", xl: "center" }} justify={{ base: "center", xl: "center" }}>
                <Stat my='auto' ms={"0px"}>
                    <StatNumber color={textColor} fontSize={{ base: "2xl", }}>
                        {items?.fname + items?.lname}
                    </StatNumber>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} w={{ base: "100%", sm: "90%" }}>
                        <Icon as={MdOutlineEmail} width='20px' height='20px' color='inherit' />
                        {items?.email?.toLowerCase()}
                    </StatLabel>
                    <Flex w={"100%"} justifyContent={"space-between"}>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            <Icon as={MdOutlinePhone} width='20px' height='20px' color='inherit' />
                            {"+" + items?.countrycode + " - " + items?.mobile}
                        </StatLabel>
                        {
                            items.isOnborded && (
                                <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                                    <Icon as={MdOutlinePassword} width='20px' height='20px' color='inherit' />
                                    {items?.tempass}
                                </StatLabel>
                            )
                        }
                    </Flex>
                </Stat>
                <Flex ms='auto' w='max-content'>
                    <Menu>
                        <MenuButton as={Button}>
                            <DragHandleIcon />
                        </MenuButton>
                        <MenuList>
                            <MenuItem gap={2} onClick={() => setemployeedetails(items)}><Icon as={MdEdit} width='20px' height='20px' color='inherit' /> Edit Employee</MenuItem>
                            <MenuItem gap={2} onClick={() => dispatch(ToogleEmployeeAction(items._id))}><Icon as={items?.isActive ? MdOutlineToggleOff : MdOutlineToggleOn} width='20px' height='20px' color='inherit' /> Toogle Employee</MenuItem>
                            {/* <MenuItem gap={2} onClick={() => dispatch(DeleteEmployeeAction(items._id))}><Icon as={MdDeleteOutline} width='20px' height='20px' color='inherit' /> Delete Employee</MenuItem> */}
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>
        </Card>
    )
}
