import React from "react";

// chakra imports
import { Box, Flex, Drawer, DrawerBody, Icon, useColorModeValue, DrawerOverlay, useDisclosure, DrawerContent, DrawerCloseButton, Stack, HStack, Text, } from "@chakra-ui/react";

// import Content from "components/sidebar/components/Content";

import { Scrollbars } from "react-custom-scrollbars-2";

import { NavLink, useLocation } from "react-router-dom";

// Assets
import { IoMenuOutline } from "react-icons/io5";
import { HSeparator } from "../../shared/separator/Separator";
import { renderTrack, renderThumb, renderView } from "../../shared/scrollbar/Scrollbar";
import { HorizonLogo, HorizonLogo2 } from "../../shared/icons/Icons";
import { AuthState } from "../../context/AuthContext";


function Routers({ routes }) {

  //   Chakra color mode
  let location = useLocation();
  let logoColor = useColorModeValue("navy.700", "white");
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>

      <Flex align='center' direction='column'>
        <HorizonLogo h='26px' w='175px' mt='32px' color={logoColor} />
        <HorizonLogo2 h='26px' w='175px' mb='32px' color={logoColor} />
        <HSeparator mb='20px' />
      </Flex>

      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          {
            routes.map((route, index) => (
              <NavLink key={index} to={route.path}>
                {
                  !route.inner && route.icon ? (
                    <Box>
                      <HStack spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"} py='5px' ps='10px'>
                        <Flex w='100%' alignItems='center' justifyContent='center'>
                          <Box color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor} me='18px'>
                            {route.icon}
                          </Box>
                          <Text me='auto' color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor} fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}>
                            {route.name}
                          </Text>
                        </Flex>
                        <Box h='36px' w='4px' bg={activeRoute(route.path.toLowerCase()) ? brandColor : "transparent"} borderRadius='5px' />
                      </HStack>
                    </Box>
                  ) : (
                    <Box>
                      <HStack spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"} py='5px' ps='10px'>
                        <Text me='auto' color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor} fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}>
                          {route.name}
                        </Text>
                        <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                      </HStack>
                    </Box>
                  )
                }
              </NavLink>
            ))
          }
        </Box>
      </Stack>
    </Flex>
  )
}

export default function Sidebar({ routes }) {

  let variantChange = "0.2s linear";
  let shadow = useColorModeValue("14px 17px 40px 4px rgba(112, 144, 176, 0.08)", "unset");
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "navy.800");

  let sidebarMargins = "0px";

  const { details } = AuthState()

  return (
    <Box display={{ sm: "none", xl: "block" }} w="100%" position='fixed' minH='100%'>
      <Box bg={sidebarBg} transition={variantChange} w='300px' h='100vh' m={sidebarMargins} minH='100%' overflowX='hidden' boxShadow={shadow}>
        <Scrollbars autoHide renderTrackVertical={renderTrack} renderThumbVertical={renderThumb} renderView={renderView}>
          <Routers routes={(details?.role === "employee") ? routes.filter((items) => (items.isPublic === true)) : routes} />
        </Scrollbars>
      </Box>
    </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive({ routes }) {
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  let menuColor = useColorModeValue("gray.400", "white");

  // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { details } = AuthState()

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems='center'>
      <Flex ref={btnRef} w='max-content' h='max-content' onClick={onOpen}>
        <Icon as={IoMenuOutline} color={menuColor} my='auto' w='20px' h='20px' me='10px' _hover={{ cursor: "pointer" }} />
      </Flex>
      <Drawer isOpen={isOpen} onClose={onClose} placement={document.documentElement.dir === "rtl" ? "right" : "left"} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent w='285px' maxW='285px' bg={sidebarBackgroundColor}>
          <DrawerCloseButton zIndex='3' onClose={onClose} _focus={{ boxShadow: "none" }} _hover={{ boxShadow: "none" }} />
          <DrawerBody maxW='285px' px='0rem' pb='0'>
            <Scrollbars autoHide renderTrackVertical={renderTrack} renderThumbVertical={renderThumb} renderView={renderView}>
              <Routers routes={(details?.role === "employee") ? routes.filter((items) => (items.isPublic === true)) : routes} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}