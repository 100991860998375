export const GET_ALL_PRODUCT_REQUEST = "GET_ALL_PRODUCT_REQUEST"

export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS"

export const GET_ALL_PRODUCT_FAILED = "GET_ALL_PRODUCT_FAILED"

export const TOOGLE_PRODUCT_REQUEST = "TOOGLE_PRODUCT_REQUEST"

export const TOOGLE_PRODUCT_SUCCESS = "TOOGLE_PRODUCT_SUCCESS"

export const TOOGLE_PRODUCT_FAILED = "TOOGLE_PRODUCT_FAILED"

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST"

export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"

export const DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAILED"