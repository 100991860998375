import axios from "../../config/axios"
import { DELETE_EMPLOYEE_FAILED, DELETE_EMPLOYEE_REQUEST, DELETE_EMPLOYEE_SUCCESS, GET_ALL_EMPLOYEE_FAILED, GET_ALL_EMPLOYEE_REQUEST, GET_ALL_EMPLOYEE_SUCCESS, TOOGLE_EMPLOYEE_FAILED, TOOGLE_EMPLOYEE_REQUEST, TOOGLE_EMPLOYEE_SUCCESS } from "../constants/EmployeeConstants"

export const GetAllEmployeeAction = (data) => (dispatch) => {
    try {
        dispatch({ type: GET_ALL_EMPLOYEE_REQUEST })
        axios.get(`/api/auth/get-all-user`, { params: data }).then(({ data }) => {
            if (data.success) {
                dispatch({ type: GET_ALL_EMPLOYEE_SUCCESS, payload: data })
            } else {
                dispatch({ type: GET_ALL_EMPLOYEE_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: GET_ALL_EMPLOYEE_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: GET_ALL_EMPLOYEE_FAILED, payload: error.toString() })
    }
}

export const ToogleEmployeeAction = (data) => (dispatch) => {
    try {
        dispatch({ type: TOOGLE_EMPLOYEE_REQUEST })
        axios.put(`/api/auth/toogle-user/${data}`).then(({ data }) => {
            if (data.success) {
                dispatch({ type: TOOGLE_EMPLOYEE_SUCCESS, payload: data })
            } else {
                dispatch({ type: TOOGLE_EMPLOYEE_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: TOOGLE_EMPLOYEE_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: TOOGLE_EMPLOYEE_FAILED, payload: error.toString() })
    }
}

export const DeleteEmployeeAction = (data) => (dispatch) => {
    try {
        dispatch({ type: DELETE_EMPLOYEE_REQUEST })
        axios.delete(`/api/auth/delete-user/${data}`).then(({ data }) => {
            if (data.success) {
                dispatch({ type: DELETE_EMPLOYEE_SUCCESS, payload: data })
            } else {
                dispatch({ type: DELETE_EMPLOYEE_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: DELETE_EMPLOYEE_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: DELETE_EMPLOYEE_FAILED, payload: error.toString() })
    }
}