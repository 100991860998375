/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { useToast } from '@chakra-ui/react'
import Loader from "../shared/loader/Loader";
import axios from "../config/axios";

const AuthContext = createContext()

export default function AuthProvider({ children }) {
    const [isAuthorized, setisAuthorized] = useState(false)
    const [details, setdetails] = useState({})

    const { loading, userdetails, userdetailserror, logout, logouterror } = useSelector((state) => state.authdetails)

    const toast = useToast()

    const GetUserDetails = () => {
        axios.get(`/api/auth/get-details`).then(({ data }) => {
            if (data.success) {
                setdetails(data.users)
                setisAuthorized(true)
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            toast({ title: error.response ? error.response.data.message : error.toString(), status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        })
    }

    useEffect(() => {
        if (userdetails && userdetails.message) {
            toast({ title: userdetails.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }

        if (userdetails && userdetails.token) {
            GetUserDetails()
        }
    }, [userdetails])

    useEffect(() => {
        if (userdetailserror) {
            toast({ title: userdetailserror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
    }, [userdetailserror])

    useEffect(() => {
        if (logout && logout.message) {
            toast({ title: logout.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }

        if (logout) {
            setisAuthorized(false)
        }
    }, [logout])

    useEffect(() => {
        if (logouterror) {
            setisAuthorized(false)
        }
    }, [logouterror])


    const states = { isAuthorized, setisAuthorized, details, setdetails }

    return (
        <AuthContext.Provider value={states}>
            {loading && <Loader visible={loading} />}
            {children}
        </AuthContext.Provider>
    )
}

export function AuthState() {
    return useContext(AuthContext)
}