/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import Loader from '../../../shared/loader/Loader'
import { GetSingleWorkAction } from '../../../redux/actions/WorkAction';
import { Box, Stat, StatLabel, useColorModeValue, Flex, useToast, Button } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import WorkDetails from '../../../components/workcomponent/WorkDetails';
import QuotationList from '../../../components/quotationcomponent/QuotationList';
import { GetAllQuotationAction } from '../../../redux/actions/QuotationAction';
import axios from '../../../config/axios';
import { useNavigate } from 'react-router-dom'
import PhotosComponent from '../../../components/quotationcomponent/PhotosComponent';
import AddPhotoModal from '../../../components/quotationcomponent/AddPhotoModal';
import ViewPhotosComponents from '../../../components/quotationcomponent/ViewPhotosComponents';

export default function QuotationView() {

    const [isLoading, setisLoading] = useState(false)
    const [toogleworkstatus, settoogleworkstatus] = useState(false)
    const [limit, setlimit] = useState(10)
    const [page, setpage] = useState(1)
    const [show, setShow] = useState(false)
    const [imageUrl, setImageUrl] = useState("")

    const location = useLocation()
    const dispatch = useDispatch()

    const textColor = useColorModeValue("secondaryGray.900", "white");

    const toast = useToast()
    const navigate = useNavigate()

    let { loading, singleworks, singleworkerror } = useSelector((state) => state.singlework)
    let { quotation, deletequotationerror } = useSelector((state) => state.deletequotation)
    let { hirequotation, hirequotationerror } = useSelector((state) => state.hirequotation)
    let { clonequotation, clonequotationerror } = useSelector((state) => state.clonequotation)

    useMemo(() => dispatch(GetSingleWorkAction(location.state.workid)), [dispatch, location, hirequotation])
    useMemo(() => dispatch(GetAllQuotationAction({ limit, page, workid: location.state.workid })), [dispatch, limit, page, quotation, clonequotation, hirequotation])

    useEffect(() => {
        if (singleworkerror) {
            toast({ title: singleworkerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }

        if (deletequotationerror) {
            toast({ title: deletequotationerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }

        if (clonequotationerror) {
            toast({ title: clonequotationerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }

        if (hirequotationerror) {
            toast({ title: hirequotationerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
    }, [singleworkerror, deletequotationerror, clonequotationerror, hirequotationerror])

    const CreateQuotation = () => {
        setisLoading(true)
        axios.get(`/api/quotation/add-quotation/${location.state.workid}`).then(({ data }) => {
            setisLoading(false)
            if (data.success) {
                toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
                navigate("/works-list/quotation-list/room-list", { state: { quotation_id: data?.quotation?._id } })
                dispatch(GetAllQuotationAction({ limit, page, workid: location.state.workid }))
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            setisLoading(false)
            toast({ title: error.response ? error.response.data.message : error.toString(), status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        })
    }

    const toogleWorkStatus = () => {
        settoogleworkstatus(true)
        axios.put(`/api/work/toogle-work-status`, { work_id: location.state.workid }).then(({ data }) => {
            settoogleworkstatus(false)
            if (data.success) {
                toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
                dispatch(GetSingleWorkAction(location.state.workid))
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            settoogleworkstatus(false)
            toast({ title: error.response ? error.response.data.message : error.toString(), status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        })
    }

    const showPhoto = (url) => {
        setShow((pre) => !pre)
        setImageUrl(url)
    }

    return (
        <>
            {loading && <Loader visible={loading} />}
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={{ base: 5 }}>
                <Flex my='auto' h='100%' align={{ base: "center", xl: "flex-start" }} justify={{ base: "center", xl: "center" }} direction={{ base: "column" }} gap={5}>
                    <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stat my='auto' ms={"0px"} gap={{ base: 5 }}>
                            <StatLabel color={textColor} fontSize={{ base: "2xl", }} textTransform={"uppercase"} fontWeight={600}>Work Details</StatLabel>
                        </Stat>
                        {
                            (singleworks?.works?.workStatus !== "completed") && (
                                <Button colorScheme='teal' size='md' isLoading={toogleworkstatus} onClick={() => toogleWorkStatus()}> {singleworks?.works?.workStatus === "pending" ? "Start Work" : singleworks?.works?.workStatus === "started" ? "Complete Work" : "Work Completed"} </Button>
                            )
                        }
                    </Flex>
                    <WorkDetails items={singleworks?.works} />
                </Flex>

                <Flex my='auto' mt={{ base: 5 }} h='100%' align={{ base: "center", xl: "flex-start" }} justify={{ base: "center", xl: "center" }} direction={{ base: "column" }} gap={5}>
                    <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stat my='auto' ms={"0px"} gap={{ base: 5 }}>
                            <StatLabel color={textColor} fontSize={{ base: "2xl", }} textTransform={"uppercase"} fontWeight={600}>Quotation List</StatLabel>
                        </Stat>
                        {
                            !singleworks?.works?.isHired && (
                                <Button colorScheme='teal' size='md' isLoading={isLoading} onClick={() => CreateQuotation()}> Add Quotation </Button>
                            )
                        }
                    </Flex>
                    <QuotationList setlimit={setlimit} setpage={setpage} isHired={singleworks?.works?.isHired} work_id={singleworks?.works?._id} />
                </Flex>

                <Flex my='auto' mt={{ base: 5 }} h='100%' align={{ base: "center", xl: "flex-start" }} justify={{ base: "center", xl: "center" }} direction={{ base: "column" }} gap={5}>
                    <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stat my='auto' ms={"0px"} gap={{ base: 5 }}>
                            <StatLabel color={textColor} fontSize={{ base: "2xl", }} textTransform={"uppercase"} fontWeight={600}>Before Start Work Picture</StatLabel>
                        </Stat>
                        {
                            singleworks?.works?.workStatus === "pending" && (
                                <AddPhotoModal workid={location.state.workid}>
                                    <Button colorScheme='teal' size='md'> Add Photo </Button>
                                </AddPhotoModal>
                            )
                        }
                    </Flex>

                    <PhotosComponent photos={singleworks?.works?.photos?.beforestart} showPhoto={showPhoto} />
                </Flex>

                <Flex my='auto' mt={{ base: 5 }} h='100%' align={{ base: "center", xl: "flex-start" }} justify={{ base: "center", xl: "center" }} direction={{ base: "column" }} gap={5}>
                    <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stat my='auto' ms={"0px"} gap={{ base: 5 }}>
                            <StatLabel color={textColor} fontSize={{ base: "2xl", }} textTransform={"uppercase"} fontWeight={600}>On Going Work Picture</StatLabel>
                        </Stat>
                        {
                            singleworks?.works?.workStatus === "started" && (
                                <AddPhotoModal workid={location.state.workid}>
                                    <Button colorScheme='teal' size='md'> Add Photo </Button>
                                </AddPhotoModal>
                            )
                        }
                    </Flex>

                    <PhotosComponent photos={singleworks?.works?.photos?.ongoing} showPhoto={showPhoto} />
                </Flex>

                <Flex my='auto' mt={{ base: 5 }} h='100%' align={{ base: "center", xl: "flex-start" }} justify={{ base: "center", xl: "center" }} direction={{ base: "column" }} gap={5}>
                    <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stat my='auto' ms={"0px"} gap={{ base: 5 }}>
                            <StatLabel color={textColor} fontSize={{ base: "2xl", }} textTransform={"uppercase"} fontWeight={600}>After Complete Work Picture</StatLabel>
                        </Stat>
                        {
                            singleworks?.works?.workStatus === "completed" && (
                                <AddPhotoModal workid={location.state.workid}>
                                    <Button colorScheme='teal' size='md'> Add Photo </Button>
                                </AddPhotoModal>
                            )
                        }
                    </Flex>

                    <PhotosComponent photos={singleworks?.works?.photos?.completed} showPhoto={showPhoto} />
                </Flex>
            </Box>
            {
                show && (
                    <ViewPhotosComponents show={show} setShow={setShow} imageUrl={imageUrl}/>
                )
            }
        </>
    )
}
