/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import Card from '../../shared/card/Card'
import { Flex, Stat, StatNumber, useColorModeValue, useToast, Text, SimpleGrid } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { GetAllEmployeeAction } from '../../redux/actions/EmployeeAction';
import Pagination from '../../shared/pagination/Pagination';
import Loader from '../../shared/loader/Loader';
import axios from '../../config/axios';
import { GetSingleWorkAction } from '../../redux/actions/WorkAction';

export default function EmployeeList({ workid }) {

    const textColor = useColorModeValue("secondaryGray.900", "white");

    let { loading, employees, employeeerror } = useSelector((state) => state.getemployee)

    const [limit, setlimit] = useState(10)
    const [page, setpage] = useState(1)

    const toast = useToast()
    const dispatch = useDispatch()

    useMemo(() => dispatch(GetAllEmployeeAction({ limit, page, work_id: workid })), [dispatch, limit, page])

    useEffect(() => {
        if (employeeerror) {
            toast({ title: employeeerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
    }, [employeeerror])

    const onSelect = (employee_id) => {
        axios.post('/api/work/add-selas-persion', { employee_id, work_id: workid }).then(({ data }) => {
            if (data.success) {
                toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
                dispatch(GetAllEmployeeAction({ limit, page, work_id: workid }))
                dispatch(GetSingleWorkAction(workid))
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            toast({ title: error.response ? error.response.data.message : error.toString(), status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        });
    }

    return (
        <Card py='15px'>
            {loading && <Loader visible={loading} />}
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }} gap='20px' mt={{ base: 6 }} mb='20px'>
                {
                    employees && employees?.users && employees?.users?.length > 0 ? employees?.users?.map((items) => (
                        <Card key={items._id} py='15px' border={"1px solid"} borderColor={"#d6d9d5"} cursor="pointer" onClick={() => onSelect(items._id)}>
                            <Flex my='auto' h='100%' align={{ base: "center", xl: "center" }} justify={{ base: "center", xl: "center" }}>
                                <Stat my='auto' ms={"0px"}>
                                    <StatNumber color={textColor} fontSize={{ base: "2xl", }}>
                                        {items.fname + " " + items.lname}
                                    </StatNumber>
                                </Stat>
                            </Flex>
                        </Card>
                    )) : (
                        <Text w={"80dvw"} paddingX={20} textAlign={"center"}> No Employee Found Active Some Employee For Display </Text>
                    )
                }
            </SimpleGrid>

            <SimpleGrid>
                {
                    employees && employees.pagination && (
                        <Pagination page={employees?.pagination?.page} limit={employees?.pagination?.limit} pageCount={employees?.pagination?.totalPage} handlePageClick={({ selected }) => setpage(selected + 1)} handleLimitChange={(e) => { setlimit(e.target.value); setpage(1) }} />
                    )
                }
            </SimpleGrid>
        </Card>
    )
}
