/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, ModalOverlay, ModalContent, Select, useColorModeValue, FormHelperText, useToast } from '@chakra-ui/react'
import { MdArrowDropDown } from 'react-icons/md';
import * as Yup from 'yup'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from '../../config/axios';
import { GetAllProductAction } from '../../redux/actions/ProductAction';
import { useDispatch } from 'react-redux'

export default function EditProduct({ limit, page, product, setproductdetails }) {

    const textColor = useColorModeValue("secondaryGray", "white");
    const toast = useToast()
    const dispatch = useDispatch()

    const [isLoading, setisLoading] = useState(false)
    const [image, setImage] = useState(null)

    const FormSchima = Yup.object().shape({
        header: Yup.string().required("Please provide product type"),
        subheader: Yup.string().required("Please provide product name")
    })

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({ resolver: yupResolver(FormSchima), mode: 'all' })

    useEffect(() => {
        setValue("header", product.name)
        setValue("subheader", product.value)
    }, [product])

    const onSubmit = (data) => {
        setisLoading(true)
        const formData = new FormData()
        formData.append('header', data.header)
        formData.append('subheader', data.subheader)
        formData.append('colorImage', image)

        axios.put(`/api/color/edit-color/${product.id}`, formData).then(({ data }) => {
            setisLoading(false)
            if (data.success) {
                toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
                dispatch(GetAllProductAction({ limit, page }))
                reset()
                setproductdetails(null)
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            setisLoading(false)
            toast({ title: error.response.data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        })
    }

    return (
        <>
            <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={() => setproductdetails(null)} isOpen={true} isCentered motionPreset='slideInBottom'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Product</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody pb={6}>
                            <FormControl mb={10}>
                                <FormLabel>Product Section</FormLabel>
                                <Select variant='outline' color={textColor} icon={<MdArrowDropDown />} {...register("header")}>
                                    <option value="" color={textColor}>Select Product Type</option>
                                    <option value={"Interior"}>Interior</option>
                                    <option value={"Exterior"}>Exterior</option>
                                    <option value={"Additional Service"}>Additional Service</option>
                                </Select>
                                {errors.header && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.header.message}</FormHelperText>}
                            </FormControl>
                            <FormControl mb={10}>
                                <FormLabel>Product Title</FormLabel>
                                <Input type='text' color={textColor} {...register("subheader")} />
                                {errors.subheader && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.subheader.message}</FormHelperText>}
                            </FormControl>

                            <FormControl mb={10}>
                                <FormLabel>Product Image</FormLabel>
                                <Input type='file' color={textColor} onChange={(e) => setImage(e.target.files[0])} />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter gap={5}>
                            <Button type='reset' colorScheme='cyan' onClick={() => reset()}> Reset </Button>
                            <Button colorScheme='teal' type='submit' isLoading={isLoading}>Submit</Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
