import { DELETE_EMPLOYEE_FAILED, DELETE_EMPLOYEE_REQUEST, DELETE_EMPLOYEE_SUCCESS, GET_ALL_EMPLOYEE_FAILED, GET_ALL_EMPLOYEE_REQUEST, GET_ALL_EMPLOYEE_SUCCESS, TOOGLE_EMPLOYEE_FAILED, TOOGLE_EMPLOYEE_REQUEST, TOOGLE_EMPLOYEE_SUCCESS } from "../constants/EmployeeConstants";

export const GetAllEmployeeReducer = (state = { employees: [] }, action) => {
    switch (action.type) {
        case GET_ALL_EMPLOYEE_REQUEST:
            return { ...state, loading: true };

        case GET_ALL_EMPLOYEE_SUCCESS:
            return { loading: false, employees: action.payload }

        case GET_ALL_EMPLOYEE_FAILED:
            return { loading: false, employeeerror: action.payload }

        default:
            return state;
    }
}

export const ToogleEmployeeReducer = (state = {}, action) => {
    switch (action.type) {
        case TOOGLE_EMPLOYEE_REQUEST:
            return { toogleloading: true };

        case TOOGLE_EMPLOYEE_SUCCESS:
            return { toogleloading: false, employee: action.payload }

        case TOOGLE_EMPLOYEE_FAILED:
            return { toogleloading: false, toogleerror: action.payload }

        default:
            return state;
    }
}

export const DeleteEmployeeReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_EMPLOYEE_REQUEST:
            return { deleteloading: true };

        case DELETE_EMPLOYEE_SUCCESS:
            return { deleteloading: false, deleteemployee: action.payload }

        case DELETE_EMPLOYEE_FAILED:
            return { deleteloading: false, deleteerror: action.payload }

        default:
            return state;
    }
}