/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { Text, useColorModeValue, Box, SimpleGrid, useToast, Button, Flex, Tabs, TabList, Tab, InputGroup, InputLeftAddon, Input, InputRightAddon } from "@chakra-ui/react";
import Pagination from '../../../shared/pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux'
import EditWork from '../../../components/workcomponent/EditWork';
import AddWork from '../../../components/workcomponent/AddWork';
import { GetAllWorkAction } from '../../../redux/actions/WorkAction';
import Loader from '../../../shared/loader/Loader';
import { SearchIcon } from '@chakra-ui/icons'
import WorkTabs from '../../../components/workcomponent/WorkTabs';

export default function WorkView() {

    const [limit, setlimit] = useState(30)
    const [page, setpage] = useState(1)
    const [search, setsearch] = useState("")
    const [day, setday] = useState("follow_up")
    const [workdetails, setworkdetails] = useState(null)

    const dispatch = useDispatch()
    const textColor = useColorModeValue("secondaryGray", "white");

    const TABARRAY = ["follow_up", "today", "tomorrow", "this_week", "hired", "completed"]

    let { loading, works, workerror } = useSelector((state) => state.getwork)
    let { toogleloading, work, toogleerror } = useSelector((state) => state.tooglework)
    let { deleteloading, deletework, deleteerror } = useSelector((state) => state.deletework)

    const toast = useToast()

    useMemo(() => dispatch(GetAllWorkAction({ limit, page, day })), [dispatch, limit, page, work, deletework, day])

    useEffect(() => {
        if (workerror) {
            toast({ title: workerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }

        if (toogleerror) {
            toast({ title: toogleerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }

        if (deleteerror) {
            toast({ title: deleteerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
    }, [workerror, toogleerror, deleteerror])

    const onTabChange = (tabindex) => {
        setpage(1)
        setsearch("")
        setday(TABARRAY[tabindex])
    }

    return (
        <>
            {(loading || toogleloading || deleteloading) && <Loader visible={loading || toogleloading || deleteloading} />}
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={{ base: 5 }}>
                <Flex alignItems={"center"} justifyContent={"space-between"} pb={{ base: 5 }}>
                    <form style={{ width: "100%" }} onSubmit={(event) => { event.preventDefault(); dispatch(GetAllWorkAction({ limit, page, day, search })) }}>
                        <InputGroup w={{ base: "75%", "3xl": "50%" }}>
                            <InputLeftAddon>
                                <SearchIcon color={"green"} />
                            </InputLeftAddon>
                            <Input type='tel' value={search} color={textColor} placeholder='Enter customer or work details ...' onChange={(e) => setsearch(e.target.value)} />
                            <InputRightAddon gap={5}>
                                <Button id='search_button' border={"none"} background={"transparent"} _hover={{ background: "transparent" }} type='submit'> Search </Button>
                                <Text>|</Text>
                                <Button border={"none"} background={"transparent"} _hover={{ background: "transparent" }} type='reset' onClick={() => { setsearch(""); dispatch(GetAllWorkAction({ limit, page, search: "" })) }}> Reset </Button>
                            </InputRightAddon>
                        </InputGroup>
                    </form>
                    <AddWork limit={limit} page={page} day={day} search={search}>
                        <Button colorScheme='teal' size='md'> Add Work </Button>
                    </AddWork>
                </Flex>

                <Tabs variant='solid-rounded' colorScheme='green' onChange={(e) => onTabChange(e)}>
                    <TabList>
                        <Tab>FOLLOW UP</Tab>
                        <Tab>TODAY</Tab>
                        <Tab>TOMORROW</Tab>
                        <Tab>THIS WEEK</Tab>
                        <Tab>HIRED</Tab>
                        <Tab>COMPLETED</Tab>
                    </TabList>
                </Tabs>
                <WorkTabs works={works} setworkdetails={setworkdetails} />

                <SimpleGrid>
                    {
                        works && works.pagination && (
                            <Pagination page={works?.pagination?.page} limit={works?.pagination?.limit} pageCount={works?.pagination?.totalPage} handlePageClick={({ selected }) => setpage(selected + 1)} handleLimitChange={(e) => { setlimit(e.target.value); setpage(1) }} />
                        )
                    }
                </SimpleGrid>

                {
                    (workdetails !== null) && (
                        <EditWork search={search} day={day} work={workdetails} limit={limit} page={page} setworkdetails={setworkdetails} />
                    )
                }
            </Box>
        </>
    )
}
