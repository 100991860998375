/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Avatar, useToast, Text, useColorModeValue, FormControl, FormLabel, Input, FormHelperText, Button } from "@chakra-ui/react";
import banner from "../../assets/img/auth/banner.png";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import { AuthState } from '../../context/AuthContext';
import Card from '../../shared/card/Card';
import axios from '../../config/axios';

export default function UpdateProfileDetails() {

    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColor = useColorModeValue("secondaryGray", "white");
    const textColorSecondary = "gray.400";
    const borderColor = useColorModeValue("white !important", "#111C44 !important");
    const bg = useColorModeValue("white", "navy.700");
    const cardShadow = useColorModeValue("0px 18px 40px rgba(112, 144, 176, 0.12)", "unset");

    const [isLoading, setisLoading] = useState(false)

    const { details, setdetails } = AuthState()

    const FormSchima = Yup.object().shape({
        fname: Yup.string().required("Please provide employee first name"),
        lname: Yup.string().required("Please provide employee last name"),
        mobile: Yup.string().required("Please provide employee mobile number").test({
            message: 'This mobile number is already used',
            test: async (value) => {
                let { data } = await axios.get(`/api/auth/check-mobile/?mobile=${value.replace(`91`, '')}&countrycode=91&id=${details?._id}`);
                return data.valid;
            },
        })
    })

    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm({ resolver: yupResolver(FormSchima), mode: 'all' })

    useEffect(() => {
        setValue("fname", details?.fname)
        setValue("lname", details?.lname)
        setValue("mobile", details?.countrycode + details?.mobile)
    }, [details])

    const toast = useToast()

    const onSubmit = (data) => {
        data = { ...data, mobile: data.mobile.replace(`91`, '').replace('+', ''), countrycode: 91 }
        setisLoading(true)
        axios.put(`/api/auth/update-user`, data).then(({ data }) => {
            setisLoading(false)
            if (data.success) {
                toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
                setdetails(data.users)
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            setisLoading(false)
            toast({ title: error.response.data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        })
    }


    return (
        <Card mb={{ base: "0px", lg: "20px" }} align='center'>
            <Box bg={`url(${banner})`} bgSize='cover' borderRadius='16px' h='131px' w='100%' />
            <Avatar _hover={{ cursor: 'pointer' }} color="white" name={details?.fname + " " + details?.lname} h='87px' w='87px' mt='-43px' border='4px solid' borderColor={borderColor} mx='auto' />
            <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
                {details?.fname + " " + details?.lname}
            </Text>
            <Text color={textColorSecondary} fontSize='sm'>
                {details?.role}
            </Text>
            <Box w='100%' mx='auto' mt='26px'>
                <Card boxShadow={cardShadow} mb='20px' bg={bg} p='14px'>
                    <Box mt={{ base: "10px", md: "0" }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl mb={10}>
                                <FormLabel>First Name</FormLabel>
                                <Input type='text' color={textColor} {...register("fname")} />
                                {errors.fname && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.fname.message}</FormHelperText>}
                            </FormControl>
                            <FormControl mb={10}>
                                <FormLabel>Last Name</FormLabel>
                                <Input type='text' color={textColor} {...register("lname")} />
                                {errors.lname && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.lname.message}</FormHelperText>}
                            </FormControl>
                            <FormControl mb={10}>
                                <FormLabel>Email Id</FormLabel>
                                <Input type='text' color={textColor} value={details?.email} disabled />
                            </FormControl>
                            <FormControl mb={10}>
                                <FormLabel>Mobile Number</FormLabel>
                                <Controller name="mobile" control={control} render={({ field: { onChange, value } }) => (
                                    <PhoneInput country={'in'} value={value} onChange={phone => onChange(phone)} containerClass='chakra-input css-1ixluli' inputStyle={{ width: "100%", height: "100%", background: "transparent", border: "none", color: textColor }} buttonStyle={{ backgroundColor: 'transparent', border: 'none' }} />
                                )} />
                                {errors.mobile && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.mobile.message}</FormHelperText>}
                            </FormControl>
                            <Button w={"100%"} colorScheme='teal' type='submit' isLoading={isLoading}> Update Details </Button>
                        </form>
                    </Box>
                </Card>
            </Box>
        </Card>
    )
}
