/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import Card from '../../shared/card/Card';
import { Box, useToast, Text, useColorModeValue, FormControl, FormLabel, Input, FormHelperText, Button, InputGroup, InputRightElement, Icon } from "@chakra-ui/react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import axios from '../../config/axios';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

export default function UpdateAccountPassword() {

    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColor = useColorModeValue("secondaryGray", "white");
    const cardShadow = useColorModeValue("0px 18px 40px rgba(112, 144, 176, 0.12)", "unset");
    const bg = useColorModeValue("white", "navy.700");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const textColorSecondary = "gray.400";

    const [isLoading, setisLoading] = useState(false)
    const [show, setShow] = React.useState({ oldpassword: false, password: false, confirmpassword: false });

    const FormSchima = Yup.object().shape({
        oldpassword: Yup.string().required("Provide your old password").min(6, "Password length must gratter than 6"),
        password: Yup.string().required("Provide your new password").min(6, "Password length must gratter than 6"),
        confirmpassword: Yup.string().required("Provide your password again").min(6, "Password length must gratter than 6").oneOf([Yup.ref("password")], "Passwords does not match")
    })

    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(FormSchima), mode: 'all' })

    const toast = useToast()

    const onSubmit = (data) => {
        setisLoading(true)
        axios.patch(`/api/auth/update-password`, data).then(({ data }) => {
            setisLoading(false)
            if (data.success) {
                toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            setisLoading(false)
            toast({ title: error.response.data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        })
    }

    return (
        <Card mb={{ base: "0px", "2xl": "20px" }}>
            <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
                Update Password
            </Text>
            <Box w='100%' mx='auto' mt='26px'>
                <Card boxShadow={cardShadow} mb='20px' bg={bg} p='14px'>
                    <Box mt={{ base: "10px", md: "0" }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl mb={20}>
                                <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                                    Old Password<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <InputGroup size='md'>
                                    <Input isRequired={true} variant='auth' fontSize='sm' ms={{ base: "0px", md: "0px" }} type={show.oldpassword ? "text" : "password"} placeholder='Min. 6 characters' mb='4px' fontWeight='500' size='lg' {...register("oldpassword")} />
                                    <InputRightElement display='flex' alignItems='center' mt='4px'>
                                        <Icon color={textColorSecondary} _hover={{ cursor: "pointer" }} as={show.oldpassword ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={() => setShow({ ...show, oldpassword: !show.password })} />
                                    </InputRightElement>
                                </InputGroup>
                                {errors.oldpassword && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.oldpassword.message}</FormHelperText>}
                            </FormControl>

                            <FormControl mb={20}>
                                <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                                    Password<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <InputGroup size='md'>
                                    <Input isRequired={true} variant='auth' fontSize='sm' ms={{ base: "0px", md: "0px" }} type={show.password ? "text" : "password"} placeholder='Min. 6 characters' mb='4px' fontWeight='500' size='lg' {...register("password")} />
                                    <InputRightElement display='flex' alignItems='center' mt='4px'>
                                        <Icon color={textColorSecondary} _hover={{ cursor: "pointer" }} as={show.password ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={() => setShow({ ...show, password: !show.password })} />
                                    </InputRightElement>
                                </InputGroup>
                                {errors.password && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.password.message}</FormHelperText>}
                            </FormControl>

                            <FormControl mb={20}>
                                <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
                                    Confirm Password<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <InputGroup size='md'>
                                    <Input isRequired={true} fontSize='sm' placeholder='Min. 6 characters' mb='4px' size='lg' type={show.confirmpassword ? "text" : "password"} variant='auth' {...register("confirmpassword")} />
                                    <InputRightElement display='flex' alignItems='center' mt='4px'>
                                        <Icon color={textColorSecondary} _hover={{ cursor: "pointer" }} as={show.confirmpassword ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={() => setShow({ ...show, confirmpassword: !show.confirmpassword })} />
                                    </InputRightElement>
                                </InputGroup>
                                {errors.confirmpassword && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.confirmpassword.message}</FormHelperText>}
                            </FormControl>
                            <Button w={"100%"} colorScheme='teal' type='submit' isLoading={isLoading}> Update Password </Button>
                        </form>
                    </Box>
                </Card>
            </Box>
        </Card>
    );
}
