/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, } from 'react'
import Loader from '../../shared/loader/Loader';
import Pagination from '../../shared/pagination/Pagination';
import { useToast, Text, SimpleGrid } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import Card from '../../shared/card/Card';
import QuotationCard from './QuotationCard';

export default function QuotationList({ setpage, setlimit, isHired, work_id }) {

    let { loading, quotations, quotationerror } = useSelector((state) => state.getquotation)
    let { deleteloading } = useSelector((state) => state.deletequotation)

    const toast = useToast()

    useEffect(() => {
        if (quotationerror) {
            toast({ title: quotationerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
    }, [quotationerror])

    return (
        <Card py='15px'>
            {(loading || deleteloading) && <Loader visible={loading || deleteloading} />}
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "xl": 2, "2xl": 2, "3xl": 6 }} gap='20px' mt={{ base: 6 }} mb='20px'>
                {
                    quotations && quotations?.quotation && quotations?.quotation?.length > 0 ? quotations?.quotation?.map((items) => (
                        <QuotationCard items={items} key={items._id} isHired={isHired} work_id={work_id} />
                    )) : (
                        <Text w={"80dvw"} paddingX={20} textAlign={"center"}> No Quotation Found Add Some Quotation For Display </Text>
                    )
                }
            </SimpleGrid>

            <SimpleGrid>
                {
                    quotations && quotations.pagination && (
                        <Pagination page={quotations?.pagination?.page} limit={quotations?.pagination?.limit} pageCount={quotations?.pagination?.totalPage} handlePageClick={({ selected }) => setpage(selected + 1)} handleLimitChange={(e) => { setlimit(e.target.value); setpage(1) }} />
                    )
                }
            </SimpleGrid>
        </Card>
    )
}
