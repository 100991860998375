import React from 'react'
import Card from '../../shared/card/Card';
import { Flex, Stat, StatNumber, useColorModeValue, Menu, MenuButton, Button, MenuList, MenuItem, Icon } from '@chakra-ui/react'
import { DragHandleIcon } from '@chakra-ui/icons'
import { MdOutlineCheck, MdOutlineContentCopy, MdSelectAll } from 'react-icons/md';
import { useDispatch } from 'react-redux'
import { CloneQuotationAction, HireQuotationAction } from '../../redux/actions/QuotationAction';
import { useNavigate } from 'react-router-dom'

export default function QuotationCard({ items, isHired, work_id }) {

    const textColor = useColorModeValue("secondaryGray.900", "white");

    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (
        <Card py='15px' border={"1px solid"} borderColor={items?.isActive ? "#27ff00" : "#ff2f00"} cursor="pointer">
            <Flex my='auto' h='100%' align={{ base: "center", xl: "center" }} justify={{ base: "center", xl: "center" }}>
                <Stat my='auto' ms={"0px"} onClick={() => navigate("/works-list/quotation-list/room-list", { state: { quotation_id: items._id } })}>
                    <StatNumber color={textColor} fontSize={{ base: "small", }}>
                        Grand Total : {items?.grandtotal}
                    </StatNumber>
                    <StatNumber color={textColor} fontSize={{ base: "small", }}>
                        Discount Total : {items?.discounttotal}
                    </StatNumber>
                    <StatNumber color={textColor} fontSize={{ base: "small", }}>
                        Grand Feet : {items?.grandfeet}
                    </StatNumber>
                    <StatNumber color={textColor} fontSize={{ base: "small", }}>
                        Discount Percentage : {items?.discount} %
                    </StatNumber>
                </Stat>

                <Flex ms='auto' w='max-content' direction={"row"} gap={{ base: 5 }} align={"center"}>
                    {
                        items.isHeired ? (
                            <Icon as={MdOutlineCheck} width='20px' height='20px' color='inherit' />
                        ) : !isHired && (
                            <Menu>
                                <MenuButton as={Button}>
                                    <DragHandleIcon />
                                </MenuButton>
                                <MenuList>
                                    {/* <MenuItem gap={2} onClick={() => dispatch(DeleteQuotationAction(items._id))}><Icon as={MdDeleteOutline} width='20px' height='20px' color='inherit' /> Delete Quotation</MenuItem> */}
                                    {
                                        items?.rooms && (items?.rooms?.length > 0) && (
                                            <>
                                                <MenuItem gap={2} onClick={() => dispatch(HireQuotationAction({ quotation_id: items._id, work_id }))}><Icon as={MdSelectAll} width='20px' height='20px' color='inherit' /> Hire Quotation</MenuItem>
                                                <MenuItem gap={2} onClick={() => dispatch(CloneQuotationAction(items._id))}><Icon as={MdOutlineContentCopy} width='20px' height='20px' color='inherit' /> Clone Quotation</MenuItem>
                                            </>
                                        )
                                    }
                                </MenuList>
                            </Menu>
                        )
                    }
                </Flex>
            </Flex>
        </Card >
    )
}
