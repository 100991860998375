import axios from "../../config/axios"
import { DELETE_ROOM_FAILED, DELETE_ROOM_REQUEST, DELETE_ROOM_SUCCESS, GET_ALL_ROOM_FAILED, GET_ALL_ROOM_REQUEST, GET_ALL_ROOM_SUCCESS } from "../constants/RoomConstants"

export const GetAllRoomsReducer = (data) => (dispatch) => {
    try {
        dispatch({ type: GET_ALL_ROOM_REQUEST })
        axios.get(`/api/quotation/get-all-room/${data}`).then(({ data }) => {
            if (data.success) {
                dispatch({ type: GET_ALL_ROOM_SUCCESS, payload: data })
            } else {
                dispatch({ type: GET_ALL_ROOM_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: GET_ALL_ROOM_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: GET_ALL_ROOM_FAILED, payload: error.toString() })
    }
}

export const DeleteRoomsReducer = (data) => (dispatch) => {
    try {
        dispatch({ type: DELETE_ROOM_REQUEST })
        axios.delete(`/api/quotation/remove-room`, { params: data }).then(({ data }) => {
            if (data.success) {
                dispatch({ type: DELETE_ROOM_SUCCESS, payload: data })
            } else {
                dispatch({ type: DELETE_ROOM_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: DELETE_ROOM_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: DELETE_ROOM_FAILED, payload: error.toString() })
    }
}