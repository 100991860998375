import React, { useState } from 'react'
import { Box, Button, Flex, FormControl, FormLabel, Heading, Icon, Input, InputGroup, InputRightElement, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import axios from '../../../config/axios';
import Loader from '../../../shared/loader/Loader';

export default function ChangePassword() {

    const toast = useToast()
    const location = useLocation()
    const navigate = useNavigate()

    const [isLoading, setisLoading] = useState(false)

    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";

    const brandStars = useColorModeValue("brand.500", "brand.400");

    const [show, setShow] = React.useState({ password: false, confirmpassword: false });

    const FormState = Yup.object().shape({
        password: Yup.string().required("Provide your new password").min(6, "Password length must gratter than 6"),
        confirmpassword: Yup.string().required("Provide your password again").min(6, "Password length must gratter than 6").oneOf([Yup.ref("password")], "Passwords does not match")
    })

    const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: 'all', resolver: yupResolver(FormState) })

    const FormSubmit = (details) => {
        setisLoading(true)
        axios.post('/api/auth/resetpassword', { ...location.state, ...details, logoutAll: false }).then(({ data }) => {
            setisLoading(false)
            if (data.success) {
                toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
                reset()
                navigate('/')
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            setisLoading(false)
            toast({ title: error.response ? error.response.data.message : error.toString(), status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        })
    }


    return (
        <>
            {isLoading && <Loader visible={isLoading} />}
            <Flex maxW={{ base: "100%", md: "max-content" }} w='100%' mx={{ base: "auto", lg: "0px" }} me='auto' h='100%' alignItems='start' justifyContent='center' mb={{ base: "30px", md: "60px" }} px={{ base: "25px", md: "0px" }} mt={{ base: "40px", md: "14vh" }} flexDirection='column'>
                <Box me='auto'>
                    <Heading color={textColor} fontSize='36px' mb='10px'>
                        Change Password
                    </Heading>
                    <Text mb='36px' ms='4px' color={textColorSecondary} fontWeight='400' fontSize='md'>
                        Enter your new password!
                    </Text>
                </Box>

                <Flex zIndex='2' direction='column' w={{ base: "100%", md: "420px" }} maxW='100%' background='transparent' borderRadius='15px' mx={{ base: "auto", lg: "unset" }} me='auto' mb={{ base: "20px", md: "auto" }}>

                    <form onSubmit={handleSubmit(FormSubmit)}>
                        <FormControl mb={'24px'}>
                            <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                                Password<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <InputGroup size='md'>
                                <Input isRequired={true} variant='auth' fontSize='sm' ms={{ base: "0px", md: "0px" }} type={show.password ? "text" : "password"} placeholder='Min. 6 characters' mb='4px' fontWeight='500' size='lg' {...register("password")} />
                                <InputRightElement display='flex' alignItems='center' mt='4px'>
                                    <Icon color={textColorSecondary} _hover={{ cursor: "pointer" }} as={show.password ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={() => setShow({ ...show, password: !show.password })} />
                                </InputRightElement>
                            </InputGroup>
                            {errors.password && <Text color={"red"}>{errors.password.message}</Text>}
                        </FormControl>

                        <FormControl mb={'24px'}>
                            <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
                                Confirm Password<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <InputGroup size='md'>
                                <Input isRequired={true} fontSize='sm' placeholder='Min. 6 characters' mb='4px' size='lg' type={show.confirmpassword ? "text" : "password"} variant='auth' {...register("confirmpassword")} />
                                <InputRightElement display='flex' alignItems='center' mt='4px'>
                                    <Icon color={textColorSecondary} _hover={{ cursor: "pointer" }} as={show.confirmpassword ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={() => setShow({ ...show, confirmpassword: !show.confirmpassword })} />
                                </InputRightElement>
                            </InputGroup>
                            {errors.confirmpassword && <Text color={"red"}>{errors.confirmpassword.message}</Text>}
                        </FormControl>

                        <Button fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='24px' type="submit" isLoading={isLoading}>
                            Update Password
                        </Button>

                    </form>

                </Flex>
            </Flex>
        </>
    )
}
