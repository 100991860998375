/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { Box, SimpleGrid, Text, useToast, Button, Flex, InputGroup, InputLeftAddon, Input, InputRightAddon, useColorModeValue } from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '../../../shared/pagination/Pagination';
import EmployeeCard from '../../../components/employeecomponent/EmployeeCard';
import AddEmployee from '../../../components/employeecomponent/AddEmployee';
import EditEmployee from '../../../components/employeecomponent/EditEmployee';
import { GetAllEmployeeAction } from '../../../redux/actions/EmployeeAction';
import Loader from '../../../shared/loader/Loader';
import { SearchIcon } from '@chakra-ui/icons'

export default function EmployeeView() {

    const [limit, setlimit] = useState(30)
    const [page, setpage] = useState(1)
    const [search, setsearch] = useState("")
    const [employeedetails, setemployeedetails] = useState(null)

    const dispatch = useDispatch()
    const toast = useToast()

    const textColor = useColorModeValue("secondaryGray", "white");

    let { loading, employees, employeeerror } = useSelector((state) => state.getemployee)
    let { toogleloading, employee, toogleerror } = useSelector((state) => state.toogleemployee)
    let { deleteloading, deleteemployee, deleteerror } = useSelector((state) => state.deleteemployee)

    useMemo(() => dispatch(GetAllEmployeeAction({ limit, page })), [dispatch, limit, page, employee, deleteemployee])

    useEffect(() => {
        if (employeeerror) {
            toast({ title: employeeerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
        if (toogleerror) {
            toast({ title: toogleerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
        if (deleteerror) {
            toast({ title: deleteerror, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
    }, [employeeerror, toogleerror, deleteerror])

    return (
        <>
            {(loading || toogleloading || deleteloading) && <Loader visible={loading || toogleloading || deleteloading} />}
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={{ base: 5 }}>
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                    <form style={{ width: "100%" }} onSubmit={(event) => { event.preventDefault(); dispatch(GetAllEmployeeAction({ limit, page, search })) }}>
                        <InputGroup w={{ base: "75%", "3xl": "50%" }}>
                            <InputLeftAddon>
                                <SearchIcon color={"green"} />
                            </InputLeftAddon>
                            <Input type='tel' value={search} color={textColor} placeholder='Enter customer or work details ...' onChange={(e) => setsearch(e.target.value)} />
                            <InputRightAddon gap={5}>
                                <Button id='search_button' border={"none"} background={"transparent"} _hover={{ background: "transparent" }} type='submit'> Search </Button>
                                <Text>|</Text>
                                <Button border={"none"} background={"transparent"} _hover={{ background: "transparent" }} onClick={() => { setsearch(""); dispatch(GetAllEmployeeAction({ limit, page, search: "" })) }}> Reset </Button>
                            </InputRightAddon>
                        </InputGroup>
                    </form>

                    <AddEmployee limit={limit} page={page}>
                        <Button colorScheme='teal' size='md'> Add Employee </Button>
                    </AddEmployee>
                </Flex>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "xl": 2, "2xl": 2, "3xl": 6 }} gap='20px' mt={{ base: 6 }} mb='20px'>
                    {
                        employees && employees?.users && employees?.users?.length > 0 ? employees.users.map((items) => (
                            <EmployeeCard items={items} key={items._id} setemployeedetails={setemployeedetails} />
                        )) : (
                            <Text w={"80dvw"} paddingX={20} textAlign={"center"}> No Employee Found Add Some Employee For Display </Text>
                        )
                    }
                </SimpleGrid>

                <SimpleGrid>
                    {
                        employees && employees.pagination && (
                            <Pagination page={employees?.pagination?.page} limit={employees?.pagination?.limit} pageCount={employees?.pagination?.totalPage} handlePageClick={({ selected }) => setpage(selected + 1)} handleLimitChange={(e) => { setlimit(e.target.value); setpage(1) }} />
                        )
                    }
                </SimpleGrid>

                {
                    (employeedetails !== null) && (
                        <EditEmployee employee={employeedetails} limit={limit} page={page} setemployeedetails={setemployeedetails} />
                    )
                }
            </Box>
        </>
    )
}
