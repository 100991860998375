export const GET_ALL_EMPLOYEE_REQUEST = "GET_ALL_EMPLOYEE_REQUEST"

export const GET_ALL_EMPLOYEE_SUCCESS = "GET_ALL_EMPLOYEE_SUCCESS"

export const GET_ALL_EMPLOYEE_FAILED = "GET_ALL_EMPLOYEE_FAILED"

export const TOOGLE_EMPLOYEE_REQUEST = "TOOGLE_EMPLOYEE_REQUEST"

export const TOOGLE_EMPLOYEE_SUCCESS = "TOOGLE_EMPLOYEE_SUCCESS"

export const TOOGLE_EMPLOYEE_FAILED = "TOOGLE_EMPLOYEE_FAILED"

export const DELETE_EMPLOYEE_REQUEST = "DELETE_EMPLOYEE_REQUEST"

export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS"

export const DELETE_EMPLOYEE_FAILED = "DELETE_EMPLOYEE_FAILED"