import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, ModalContent, Select, useColorModeValue, Checkbox, FormHelperText, useToast } from '@chakra-ui/react'
import { MdArrowDropDown } from 'react-icons/md';
import * as Yup from 'yup'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from '../../config/axios';
import { GetAllProductAction } from '../../redux/actions/ProductAction';
import { useDispatch } from 'react-redux'

export default function AddProduct({ children, limit, page }) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [isLoading, setIsLoading] = useState(false)
    const [image, setImage] = useState(null)

    const textColor = useColorModeValue("secondaryGray", "white");
    const toast = useToast()
    const dispatch = useDispatch()

    const FormSchema = Yup.object().shape({
        isActive: Yup.boolean().required("Please provide product status"),
        header: Yup.string().required("Please provide product type"),
        subheader: Yup.string().required("Please provide product name")
    })

    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(FormSchema), mode: 'all' })

    useEffect(() => {
        setImage(null)
        return () => {
            setImage(null)
        }
    }, [])

    const onSubmit = (data) => {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('isActive', data.isActive)
        formData.append('header', data.header)
        formData.append('subheader', data.subheader)
        formData.append('colorImage', image)

        axios.post(`/api/color/add-color`, formData).then(({ data }) => {
            setIsLoading(false)
            if (data.success) {
                toast({ title: data.message, status: 'success', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
                dispatch(GetAllProductAction({ limit, page }))
                onClose()
                reset()
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            setIsLoading(false)
            toast({ title: error.response.data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        })
    }

    return (
        <>
            {
                children && (<span onClick={onOpen}>{children}</span>)
            }

            <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered motionPreset='slideInBottom'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Product</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody pb={6}>
                            <FormControl mb={10}>
                                <FormLabel>Product Section</FormLabel>
                                <Select variant='outline' color={textColor} icon={<MdArrowDropDown />} {...register("header")}>
                                    <option value="" color={textColor}>Select Product Type</option>
                                    <option value={"Interior"}>Interior</option>
                                    <option value={"Exterior"}>Exterior</option>
                                    <option value={"Additional Service"}>Additional Service</option>
                                </Select>
                                {errors.header && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.header.message}</FormHelperText>}
                            </FormControl>
                            <FormControl mb={10}>
                                <FormLabel>Product Title</FormLabel>
                                <Input type='text' color={textColor} {...register("subheader")} />
                                {errors.subheader && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.subheader.message}</FormHelperText>}
                            </FormControl>
                            <FormControl mb={10}>
                                <FormLabel>Product Image</FormLabel>
                                <Input type='file' color={textColor} onChange={(e) => setImage(e.target.files[0])} />
                            </FormControl>
                            <FormControl mb={6}>
                                <Checkbox colorScheme='green' {...register("isActive")}>
                                    This product is active or not
                                </Checkbox>
                                {errors.isActive && <FormHelperText w={"100%"} textAlign={"center"} color={"red.400"} position={"absolute"}>{errors.isActive.message}</FormHelperText>}
                            </FormControl>
                        </ModalBody>

                        <ModalFooter gap={5}>
                            <Button type='reset' colorScheme='cyan' onClick={() => reset()}> Reset </Button>
                            <Button colorScheme='teal' type='submit' isLoading={isLoading}>Submit</Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
