import { Icon } from "@chakra-ui/react";
import { MdOutlineShoppingCart, MdOutlineWorkOutline, MdPersonOutline } from "react-icons/md";
import WorkView from "./views/dashboard/work/WorkView";
import ProductView from "./views/dashboard/product/ProductView";
import EmployeeView from "./views/dashboard/employee/EmployeeView";
import SelectSealPerson from "./views/dashboard/work/SelectSealPerson";
import QuotationView from "./views/dashboard/quotation/QuotationView";
import RoomView from "./views/dashboard/room/RoomView";
import Profile from "./views/auth/profile/Profile";
import SignIn from "./views/auth/signIn";
import VerifyOtp from "./views/auth/verifyotp/VerifyOtp";
import ChangePassword from "./views/auth/changepassword/ChangePassword";

const routes = [
    {
        name: "Work List",
        path: "/works-list",
        icon: <Icon as={MdOutlineWorkOutline} width='20px' height='20px' color='inherit' />,
        component: WorkView,
        isMain: true,
        isPublic: true
    },
    {
        name: "Product List",
        path: "/product-list",
        icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
        component: ProductView,
        isMain: true,
    },
    {
        name: "Employee List",
        path: "/employee-list",
        icon: <Icon as={MdPersonOutline} width='20px' height='20px' color='inherit' />,
        component: EmployeeView,
        isMain: true,
    },
    {
        name: "Choose Seal Person",
        path: "/works-list/seals-person",
        component: SelectSealPerson,
        isMain: false,
    },
    {
        name: "Work Details",
        path: "/works-list/quotation-list",
        component: QuotationView,
        isMain: false,
    },
    {
        name: "Room List",
        path: "/works-list/quotation-list/room-list",
        component: RoomView,
        isMain: false,
    },
    {
        name: "Profile Details",
        path: "/profile-details",
        component: Profile,
        isMain: false,
    },
];

export default routes;


export const authroutes = [
    {
        path: "/",
        component: SignIn,
    },
    {
        path: "/verify-otp",
        component: VerifyOtp
    },
    {
        path: '/change-password',
        component: ChangePassword
    }
]