import { CLONE_QUOTATION_FAILED, CLONE_QUOTATION_REQUEST, CLONE_QUOTATION_SUCCESS, DELETE_QUOTATION_FAILED, DELETE_QUOTATION_REQUEST, DELETE_QUOTATION_SUCCESS, GET_ALL_QUOTATION_FAILED, GET_ALL_QUOTATION_REQUEST, GET_ALL_QUOTATION_SUCCESS, HIRE_QUOTATION_FAILED, HIRE_QUOTATION_REQUEST, HIRE_QUOTATION_SUCCESS } from "../constants/QuotationConstants";

export const GetAllQuotationReducer = (state = { quotations: [] }, action) => {
    switch (action.type) {
        case GET_ALL_QUOTATION_REQUEST:
            return { ...state, loading: true };

        case GET_ALL_QUOTATION_SUCCESS:
            return { loading: false, quotations: action.payload };

        case GET_ALL_QUOTATION_FAILED:
            return { loading: false, quotationerror: action.payload };

        default:
            return state;
    }
}

export const DeleteQuotationReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_QUOTATION_REQUEST:
            return { deleteloading: true };

        case DELETE_QUOTATION_SUCCESS:
            return { deleteloading: false, quotation: action.payload };

        case DELETE_QUOTATION_FAILED:
            return { deleteloading: false, deletequotationerror: action.payload };

        default:
            return state;
    }
}

export const HireQuotationReducer = (state = {}, action) => {
    switch (action.type) {
        case HIRE_QUOTATION_REQUEST:
            return { hireloading: true };

        case HIRE_QUOTATION_SUCCESS:
            return { hireloading: false, hirequotation: action.payload };

        case HIRE_QUOTATION_FAILED:
            return { hireloading: false, hirequotationerror: action.payload };

        default:
            return state;
    }
}

export const CloneQuotationReducer = (state = {}, action) => {
    switch (action.type) {
        case CLONE_QUOTATION_REQUEST:
            return { cloneloading: true };

        case CLONE_QUOTATION_SUCCESS:
            return { cloneloading: false, clonequotation: action.payload };

        case CLONE_QUOTATION_FAILED:
            return { cloneloading: false, clonequotationerror: action.payload };

        default:
            return state;
    }
}