import React from 'react'
import { MdOutlineCalendarMonth, MdOutlineHomeWork, MdOutlineLocationOn, MdOutlinePhone } from 'react-icons/md';
import { Flex, Stat, StatLabel, StatNumber, useColorModeValue, Icon, Badge, StatHelpText, HStack } from '@chakra-ui/react'
import Card from '../../shared/card/Card';
import moment from 'moment';
import { AuthState } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom'

export default function WorkDetails({ items }) {

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "secondaryGray.800";
    const navigate = useNavigate()

    const { details } = AuthState()

    return (
        <Card py='15px' border={"1px solid"} borderColor={items?.isActive ? "#27ff00" : "#ff2f00"} cursor="pointer">
            <Flex my='auto' h='100%' align={{ base: "center", xl: "center" }} justify={{ base: "center", xl: "center" }}>
                <Stat my='auto' ms={"0px"} gap={{ base: 5 }}>
                    <StatNumber color={textColor} fontSize={{ base: "2xl", }}>
                        Customer Details
                    </StatNumber>

                    <StatNumber color={textColor} fontSize={{ base: "xl", }}>
                        {items?.customerName}
                    </StatNumber>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                        <Icon as={MdOutlineLocationOn} width='20px' height='20px' color='inherit' />
                        {items?.customerAddress}
                    </StatLabel>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} w={{ base: "100%", sm: "90%" }} mt={{ base: 2 }}>
                        <Icon as={MdOutlinePhone} width='20px' height='20px' color='inherit' />
                        {"+" + items?.customercountrycode + " - " + items?.customermobile}
                    </StatLabel>
                </Stat>
                <Stat my='auto' ms={"0px"} gap={{ base: 5 }}>
                    <StatNumber color={textColor} fontSize={{ base: "2xl", }}>
                        Project Details
                    </StatNumber>

                    <StatNumber color={textColor} fontSize={{ base: "xl", }}>
                        {items?.projectName}
                    </StatNumber>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                        <Icon as={MdOutlineLocationOn} width='20px' height='20px' color='inherit' />
                        {items?.location}
                    </StatLabel>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} w={{ base: "100%", sm: "90%" }} mt={{ base: 2 }}>
                        Consultation Status : {items?.consultationStatus}
                    </StatLabel>
                </Stat>
            </Flex>

            <Flex my='auto' h='100%' align={{ base: "flex-end", xl: "flex-end" }} justify={{ base: "center", xl: "center" }} mt={{ base: 5 }} w={"100%"}>
                {
                    (details?.role !== "employee") && (
                        <Stat ms={"0px"} gap={{ base: 5 }} onClick={() => navigate("/works-list/seals-person", { state: { workid: items._id } })}>
                            <HStack justifyContent={"center"} alignItems={"center"}>
                                <StatNumber lineHeight='100%' color={textColor} fontSize={{ base: "sm", }} columnGap={{ base: 2 }}> Selas Person : </StatNumber>
                                <StatHelpText whiteSpace={"nowrap"} mb={0}>{items?.salesPerson && items?.salesPerson?.fname + " " + items?.salesPerson?.lname}</StatHelpText>
                            </HStack>
                        </Stat>
                    )
                }
                <Stat ms={"0px"} gap={{ base: 5 }}>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                        <Icon as={MdOutlineCalendarMonth} width='20px' height='20px' color='inherit' />
                        {moment(items?.consultationDate).format("DD-MM-YYYY HH:mm")}
                    </StatLabel>
                </Stat>
            </Flex>

            <Flex my='auto' h='100%' align={{ base: "flex-end", xl: "flex-end" }} justify={{ base: "center", xl: "center" }} mt={{ base: 5 }} w={"100%"}>
                <Badge px={5} py={5} colorScheme={(items?.workStatus === "pending") ? 'purple' : (items?.workStatus === "completed") ? 'green' : 'blue'}>
                    <Flex alignItems={"center"}>
                        <Icon as={MdOutlineHomeWork} width='40px' height='40px' color='inherit' />
                        <Stat my='auto' ms={"18px"} borderLeft={'1px solid #a39999'} paddingLeft={{ base: 5 }}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "sm", }} mb={{ base: 2 }}>
                                {items?.workStatus}
                            </StatLabel>
                            <StatNumber lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }}>
                                {moment(items?.workStatusDate).format('DD-MM-YYYY HH:mm')}
                            </StatNumber>
                        </Stat>
                    </Flex>
                </Badge>
            </Flex>
        </Card>
    )
}
