import React from 'react'
import Card from '../../shared/card/Card'
import { Flex, Stat, StatNumber, StatLabel, Icon, Menu, MenuButton, MenuList, Button, MenuItem, useColorModeValue } from '@chakra-ui/react'
import { MdDeleteOutline, MdEdit, MdOutlineColorLens } from 'react-icons/md';
import { DeleteRoomsReducer } from '../../redux/actions/RoomAction';
import { useDispatch } from 'react-redux'
import { DragHandleIcon } from '@chakra-ui/icons'

export default function RoomCard({ items, setroomdetails, quotation, pdfurl }) {

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "secondaryGray.800";

    const dispatch = useDispatch()

    return (
        <Card py='15px' cursor="pointer">
            <Flex my='auto' h='100%' align={{ base: "center", xl: "center" }} justify={{ base: "center", xl: "center" }}>
                <Stat my='auto' ms={"0px"} gap={{ base: 5 }}>
                    <StatNumber color={textColor} fontSize={{ base: "2xl", }} mb={{ base: 3 }}>
                        {items?.roomname}
                    </StatNumber>

                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "xl", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} w={{ base: "100%", sm: "90%" }} mt={{ base: 2 }}>
                        Celling
                    </StatLabel>
                    <Flex justify={{ base: "space-around" }} mt={{ base: 3 }}>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            Total Feet : {items?.celling?.totalfeet}
                        </StatLabel>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            Total Price : {items?.celling?.price}
                        </StatLabel>
                    </Flex>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "small", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} w={{ base: "100%", sm: "90%" }} mt={{ base: 5 }}>
                        Feet Details
                    </StatLabel>
                    <Flex justify={{ base: "space-around" }} mt={{ base: 3 }}>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            Start Feet : {items?.celling?.feet?.from}
                        </StatLabel>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            End Feet : {items?.celling?.feet?.to}
                        </StatLabel>
                    </Flex>
                    <Flex justify={{ base: "space-around" }} mt={{ base: 3 }}>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            Remove Start Feet : {items?.celling?.removedfeet?.from}
                        </StatLabel>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            Remove End Feet : {items?.celling?.removedfeet?.to}
                        </StatLabel>
                    </Flex>

                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} justifyContent={"center"} columnGap={{ base: 2 }} mt={{ base: 5 }}>
                        <Icon as={MdOutlineColorLens} width='20px' height='20px' color='inherit' />
                        {(items?.celling?.othercolor !== "") ? items?.celling?.othercolor : items?.celling?.color?.subheader}
                    </StatLabel>

                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "xl", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} w={{ base: "100%", sm: "90%" }} mt={{ base: 2 }}>
                        Wall
                    </StatLabel>
                    <Flex justify={{ base: "space-around" }} mt={{ base: 3 }}>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            Total Feet : {items?.wall?.totalfeet}
                        </StatLabel>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            Total Price : {items?.wall?.price}
                        </StatLabel>
                    </Flex>
                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "small", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} w={{ base: "100%", sm: "90%" }} mt={{ base: 5 }}>
                        Feet Details
                    </StatLabel>
                    <Flex justify={{ base: "space-around" }} mt={{ base: 3 }}>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            Start Feet : {items?.wall?.feet?.from}
                        </StatLabel>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            End Feet : {items?.wall?.feet?.to}
                        </StatLabel>
                    </Flex>
                    <Flex justify={{ base: "space-around" }} mt={{ base: 3 }}>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            Remove Start Feet : {items?.wall?.removedfeet?.from}
                        </StatLabel>
                        <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} columnGap={{ base: 2 }} mt={{ base: 2 }}>
                            Remove End Feet : {items?.wall?.removedfeet?.to}
                        </StatLabel>
                    </Flex>

                    <StatLabel lineHeight='100%' color={textColorSecondary} fontSize={{ base: "sm", }} alignItems={"center"} display={"flex"} justifyContent={"center"} columnGap={{ base: 2 }} mt={{ base: 5 }}>
                        <Icon as={MdOutlineColorLens} width='20px' height='20px' color='inherit' />
                        {(items?.wall?.othercolor !== "") ? items?.wall?.othercolor : items?.wall?.color?.subheader}
                    </StatLabel>
                </Stat>

                <Flex ms='auto' w='max-content' direction={"column"} gap={{ base: 10 }}>
                    {
                        pdfurl === "" && (
                            <Menu>
                                <MenuButton as={Button}>
                                    <DragHandleIcon />
                                </MenuButton>
                                <MenuList>
                                    <MenuItem gap={2} onClick={() => setroomdetails(items)}><Icon as={MdEdit} width='20px' height='20px' color='inherit' /> Edit Room</MenuItem>
                                    <MenuItem gap={2} onClick={() => dispatch(DeleteRoomsReducer({ quotation, roomid: items._id }))}><Icon as={MdDeleteOutline} width='20px' height='20px' color='inherit' /> Delete Room</MenuItem>
                                </MenuList>
                            </Menu>
                        )
                    }
                </Flex>
            </Flex>
        </Card>
    )
}
