import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Text, useColorModeValue, useToast } from "@chakra-ui/react";

import OtpInput from 'react-otp-input';
import axios from "../../../config/axios";

export default function VerifyOtp() {

    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";

    const [otp, setOtp] = useState('')
    const [loading, setLoading] = useState(false)

    const brandStars = useColorModeValue("brand.500", "brand.400");

    const navigate = useNavigate()
    const location = useLocation()

    const toast = useToast()

    const FormSubmit = (event) => {
        event.preventDefault()
        if (otp.length !== 6) {
            return toast({ title: 'Enter your otp', status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        }
        setLoading(true);
        axios.post('/api/auth/verifyotp', { email: location.state.email, otp }).then(({ data }) => {
            setLoading(false);
            if (data.success) {
                navigate('/change-password', { state: { email: location.state.email, otp: otp } });
            } else {
                toast({ title: data.message, status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
            }
        }).catch((error) => {
            setLoading(false);
            toast({ title: error.response ? error.response.data.message : error.toString(), status: 'error', duration: 5000, isClosable: true, position: 'top-right', variant: 'left-accent' })
        });
    }


    return (
        <Flex maxW={{ base: "100%", md: "max-content" }} w='100%' mx={{ base: "auto", lg: "0px" }} me='auto' h='100%' alignItems='start' justifyContent='center' mb={{ base: "30px", md: "60px" }} px={{ base: "25px", md: "0px" }} mt={{ base: "40px", md: "14vh" }} flexDirection='column'>
            <Box me='auto'>
                <Heading color={textColor} fontSize='36px' mb='10px'>
                    Verify Otp
                </Heading>
                <Text mb='36px' ms='4px' color={textColorSecondary} fontWeight='400' fontSize='md'>
                    Enter 6 digit verification code sent to your registered email address.
                </Text>
            </Box>

            <Flex zIndex='2' direction='column' w={{ base: "100%", md: "420px" }} maxW='100%' background='transparent' borderRadius='15px' mx={{ base: "auto", lg: "unset" }} me='auto' mb={{ base: "20px", md: "auto" }}>

                <form>
                    <FormControl mb={'24px'}>
                        <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                            Enter Otp <Text color={brandStars}>*</Text>
                        </FormLabel>
                        <OtpInput value={otp} onChange={setOtp} numInputs={6} containerStyle={{ gap: '10px', margin: '15px' }} shouldAutoFocus renderSeparator={<span>-</span>} skipDefaultStyles={true} renderInput={(props) => <Input fontSize='sm' ms={{ base: "0px", md: "0px" }} mb='4px' fontWeight='500' size='lg' background={'transparent'} backgroundColor={'transparent'} color={"inherit"} {...props} />} />
                    </FormControl>

                    <Button fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='24px' type="submit" isLoading={loading} onClick={(e) => FormSubmit(e)}>
                        Verify Otp
                    </Button>

                </form>

            </Flex>
        </Flex>
    )
}
