import Axios from "axios";

const axios = Axios.create({ baseURL: process.env.REACT_APP_API_URL })

axios.interceptors.request.use(config => {
    if (localStorage.getItem('token')) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    return config
}, (error => {
    Promise.reject(error)
}))

axios.interceptors.response.use(response => {
    return response

}, (error => {

    if (error.response.status === 402) {
        localStorage.clear()
    }

    Promise.reject(error)
}))


export default axios