import React from 'react'
import { Navigate, Outlet } from "react-router-dom";
import { AuthState } from './context/AuthContext';

export function BeforeLogin() {

    const { isAuthorized, details } = AuthState()
    let location = sessionStorage.getItem("location")
    let locationState = sessionStorage.getItem("locationState")

    let currentNavigate = location ? <Navigate to={location} replace={true} state={JSON.parse(locationState)} /> : <Navigate to={'/works-list'} />

    return isAuthorized ? !details?.isOnborded ? currentNavigate : <Navigate to={'/onborerded'} /> : <Outlet />
}

export function OnBorder() {

    const { isAuthorized, details } = AuthState()
    let location = sessionStorage.getItem("location")
    let locationState = sessionStorage.getItem("locationState")

    let currentNavigate = location ? <Navigate to={location} replace={true} state={JSON.parse(locationState)} /> : <Navigate to={'/works-list'} />

    return isAuthorized ? !details?.isOnborded ? currentNavigate : <Outlet /> : <Navigate to={'/'} />
}

export function AfterLogin() {

    const { isAuthorized, details } = AuthState()

    return isAuthorized ? !details?.isOnborded ? <Outlet /> : <Navigate to={'/onborerded'} /> : <Navigate to={'/'} />
}