/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Box, Grid } from "@chakra-ui/react";
import UpdateProfileDetails from '../../../components/profilecomponent/UpdateProfileDetails';
import UpdateAccountPassword from '../../../components/profilecomponent/UpdateAccountPassword';

export default function Profile() {


    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Grid templateColumns={{ base: "1fr", lg: "1.34fr 1fr 1.62fr", }} templateRows={{ base: "repeat(3, 1fr)", lg: "1fr", }} gap={{ base: "20px", xl: "20px" }}>
                <UpdateProfileDetails />
                <UpdateAccountPassword />
            </Grid>
        </Box>
    )
}
